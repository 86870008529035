import { Image } from '@mantine/core';

export interface LogoProps {
  width: number | null;
}

export function Logo(props: LogoProps) {
  return (
    <Image
      width={props.width ?? 142}
      src={'/barntalk_logo.svg'}
      alt="BarnTalk Logo"
    />
  );
}
