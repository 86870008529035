import { ButtonStylesParams, MantineTheme } from '@mantine/core';

export const ButtonStyles = (
  theme: MantineTheme,
  params: ButtonStylesParams
) => {
  const btBlue = theme.colors.blue[5];
  const btGreen = theme.colors.green[3];
  const btRed = theme.colors.red[6];
  const btYellow = theme.colors.yellow[2];
  const btMedText = theme.colors.brand[7];

  let color = btMedText;
  let bg = params.color;
  let border = params.color;
  let bgHover = border;
  let colorHover = 'white';
  let borderHover = 'white';

  switch (params.color) {
    case 'gray':
      color = btMedText;
      border = theme.colors.gray[4];
      bg = theme.colors.gray[2];
      bgHover = border;
      colorHover = theme.fn.darken(color, 0.25);
      borderHover = theme.fn.darken(border, 0.1);
      break;
    case 'white':
      color = btMedText;
      border = theme.colors.gray[4];
      bg = 'white';

      bgHover = border;
      colorHover = theme.fn.darken(color, 0.25);
      borderHover = theme.fn.darken(border, 0.1);
      break;
    case undefined:
      color = btMedText;
      border = theme.colors.brand[1];
      bg = 'white';

      bgHover = border;
      colorHover = theme.fn.darken(color, 0.25);
      borderHover = theme.fn.darken(border, 0.1);
      break;
    case 'blue':
      color = 'white';
      border = btBlue;
      bg = btBlue;

      bgHover = theme.fn.darken(bg, 0.15);
      colorHover = 'white';
      borderHover = theme.fn.darken(bg, 0.15);
      break;
    case 'red':
      color = 'white';
      border = btRed;
      bg = btRed;

      bgHover = theme.fn.darken(bg, 0.15);
      colorHover = 'white';
      borderHover = theme.fn.darken(bg, 0.15);
      break;
    case 'orange':
    case 'yellow':
      color = 'white';
      border = btYellow;
      bg = btYellow;

      bgHover = theme.fn.darken(bg, 0.15);
      colorHover = 'white';
      borderHover = theme.fn.darken(bg, 0.15);
      break;
    case 'green':
      color = 'white';
      border = btGreen;
      bg = btGreen;

      bgHover = theme.fn.darken(bg, 0.15);
      colorHover = 'white';
      borderHover = theme.fn.darken(bg, 0.15);
      break;
    case 'dark':
      color = 'white';
      border = btMedText;
      bg = btMedText;

      bgHover = theme.fn.darken(bg, 0.25);
      colorHover = 'white';
      borderHover = theme.fn.darken(bg, 0.15);
      break;
  }

  if (params.variant == 'outline') {
    color = bg;
    bg = 'white';
  }

  return {
    root: {
      padding: '10px 16px',

      background: bg,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: border,

      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      borderRadius: '8px',
      color: color,
      transition: 'all 0.25s ease',

      '&:hover': {
        backgroundColor: bgHover,
        borderColor: borderHover,
        boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.15)',
        color: colorHover,
      },

      '&:active': {
        backgroundColor: theme.fn.darken(bgHover, 0.1),
      },
    },
  };
};
