import {
  Alert,
  Button,
  Container,
  Group,
  Modal,
  TextInput,
} from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';
import { useState } from 'react';
import { useUpdateGatewayDetailsMutation } from '../../../api/gateways';
import { IGateway } from '../../../api/types';

type Props = {
  onClose: () => void;
  gateway: IGateway;
};

export function EditBarnModal({ onClose, gateway }: Props) {
  const [newName, setNewName] = useState(gateway.name);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [updateGateway] = useUpdateGatewayDetailsMutation();
  const handleSubmit = () => {
    if (newName.length > 0) {
      setIsSaving(true);
      setErrorMessage('');
      updateGateway({
        gatewaySerial: gateway.serialNumber,
        gatewayName: newName,
      })
        .unwrap()
        .then(
          () => {
            setIsSaving(false);
            onClose();
            showNotification({
              title: 'Barn Changes Saved',
              message: 'Barn details have been updated.',
              icon: <IconCheck />,
              color: 'blue',
            });
          },
          (e) => {
            setErrorMessage(e);
          }
        );
    } else {
      onClose();
    }
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal opened={true} onClose={onClose} title={'Edit Barn'}>
      <TextInput
        data-testid="name-input"
        aria-label="Barn Name"
        label="Barn Name"
        data-autofocus
        onKeyDown={getHotkeyHandler([['enter', handleSubmit]])}
        onChange={(e) => setNewName(e.target.value)}
        value={newName}
      />
      {errorMessage && (
        <Container p="md">
          <Alert color={'red'}>{errorMessage}</Alert>
        </Container>
      )}
      <Group pt="md" position="right">
        <Button color="gray" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          data-testid="save-button"
          disabled={isSaving}
          onClick={handleSubmit}
          color="blue"
        >
          {isSaving ? 'Saving' : 'Save'}
        </Button>
      </Group>
    </Modal>
  );
}
