import {
  Alert,
  createStyles,
  Flex,
  Grid,
  Group,
  Loader,
  Text,
} from '@mantine/core';
import { ReactElement, ReactNode } from 'react';
import { ColumnProps, DataTableSortStatus } from '.';
import DataTableHeader from './DataTableHeader';

type DataItem = { id: string };

type DataTableProps<T extends DataItem> = {
  // A description of the data in each column of the table
  columns: ColumnProps<T>[];

  // The data to render in the table
  data: T[];

  // Whether to render the headers or not, used if you want
  // to render the headers "detached" by directly using the
  // DataTableHeader component
  renderHeaders: boolean;

  onSort?: (status: DataTableSortStatus) => void;
  onRowClick?: (item: T) => void;
  sortStatus?: DataTableSortStatus;
  textWhenNoData?: string;
  hasError?: boolean;
  isLoading?: boolean;
};

function DataTable<T extends DataItem>(props: DataTableProps<T>) {
  const { classes, cx } = useStyles();
  const rows = [];
  if (props.hasError) {
    rows.push(
      wrapInRow(
        <Alert color="red" key="error" data-testid="datatable.error">
          There was an error fetching the list.
        </Alert>,
        'datatable-error',
        classes
      )
    );
  } else if (props.isLoading) {
    rows.push(
      wrapInRow(
        <Group position="center" data-testid="datatable.loading">
          <Loader></Loader>
          <Text size={18}>Loading...</Text>
        </Group>,
        'loading',
        classes
      )
    );
  } else {
    for (let i = 0; i < props.data.length; i++) {
      rows.push(
        createRow(
          props.data[i],
          i,
          props.columns,
          classes,
          cx,
          props.onRowClick
        )
      );
    }
    if (rows.length === 0) {
      rows.push(
        wrapInRow(
          <Text key="no-items" data-testid="datatable.no-data">
            {props.textWhenNoData ?? 'No data'}
          </Text>,
          'no-results',
          classes
        )
      );
    }
  }
  return (
    <Flex direction="column" data-testid="datatable.rows">
      {props.renderHeaders && (
        <DataTableHeader
          key="table header"
          columns={props.columns}
          sortStatus={props.sortStatus}
          onSort={props.onSort}
        />
      )}
      {rows}
    </Flex>
  );
}

function wrapInRow(
  child: ReactNode,
  key: string,
  classes: { tableRow: string }
) {
  return (
    <Grid key={key} className={classes.tableRow}>
      <Grid.Col span={12}>{child}</Grid.Col>
    </Grid>
  );
}

function createRow<T extends DataItem>(
  item: T,
  index: number,
  columns: ColumnProps<T>[],
  classes: Record<
    'unimportant' | 'tableRow' | 'clickable' | 'control' | 'icon',
    string
  >,
  cx: { (...args: unknown[]): string },
  onRowClick: ((item: T) => void) | undefined
): ReactElement {
  const cells = columns.map((column) => {
    return (
      <Grid.Col
        role={'gridcell'}
        key={`${column.accessor}`}
        span={column.span}
        className={column.important ? '' : classes.unimportant}
        onClick={onRowClick ? () => onRowClick(item) : undefined}
      >
        {column.render
          ? column.render(item, index)
          : (getValueAtPath(item, column?.accessor ?? '') as ReactNode)}
      </Grid.Col>
    );
  });
  return (
    <Grid
      data-testid="datatable.row"
      key={`row${index}`}
      className={cx(classes.tableRow, {
        [classes.clickable]: onRowClick,
      })}
    >
      {cells}
    </Grid>
  );
}

function getValueAtPath(obj: unknown, path: string) {
  const pathArray = path.match(/([^[.\]])+/g) as string[];
  return pathArray.reduce(
    (prevObj: unknown, key) =>
      prevObj && (prevObj as Record<string, unknown>)[key],
    obj
  );
}

export default DataTable;

const useStyles = createStyles((theme) => ({
  unimportant: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: 'none',
    },
  },

  tableRow: {
    margin: 0,
    background: 'white',
    border: 'solid rgba(234, 236, 240, 1) 1px',
    borderTop: 'none',
    padding: '1rem',
    whiteSpace: 'nowrap',
  },

  clickable: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.fn.lighten(theme.colors.brand[0], 0.5),
    },
  },

  control: {
    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.fn.lighten(theme.colors.brand[0], 0.5),
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
