import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconBellOff } from '@tabler/icons';

export const openDisableBarnAlarmModal = () =>
  openConfirmModal({
    title: 'Disable Alarms ',
    children: (
      <>
        <Text size="sm">
          Are you sure you want to silence all alarms from this barn? Emergency
          and warnings will not trigger calls or text messages until re-enabled.
        </Text>
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          [TODO: Implement functionality]
        </Text>
      </>
    ),
    labels: { confirm: 'Disable', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    cancelProps: { color: 'gray' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () =>
      showNotification({
        title: 'Barn Alarms Disabled',
        message: 'You will no longer recieve alarms from this barn.',
        icon: <IconBellOff />,
        color: 'red',
      }),
  });
