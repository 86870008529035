import { Button, createStyles, Flex, Loader, Text, Title } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconPlus, IconSearch } from '@tabler/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import ScrollablePage from '../../components/ScrollablePage';
import { SearchInput } from '../../components/SearchInput';
import { roles } from '../../Permissions/OrganizationRoles';
import { defaultOrganization } from '../../profile/helpers';
import AddMemberModal from './AddMemberModal';
import UserList from './UserList';

function PeoplePage() {
  const selectedOrganization = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );
  const profile = useSelector(
    (state: RootState) => state.organizations.profile
  );
  const organization = selectedOrganization ?? defaultOrganization(profile);
  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);
  const [search, setSearch] = useDebouncedState('', 200);

  return (
    <>
      <ScrollablePage
        fixedHeaderContent={
          <>
            <Flex gap="md" className={classes.title}>
              <Title sx={{ flex: 1 }}>
                People {organization ? `in ${organization.name}` : null}
              </Title>
              {profile?.Memberships.some(
                (m) =>
                  m.Organization.id === organization?.id &&
                  roles[m.RoleID].abilities.canCreate
              ) === true ? (
                <Button
                  leftIcon={<IconPlus />}
                  variant="outline"
                  color="dark"
                  onClick={() => {
                    setOpened(true);
                  }}
                >
                  Add
                </Button>
              ) : null}
              <SearchInput
                placeholder="Search People"
                width={'10rem'}
                icon={<IconSearch size={14} stroke={1.5} />}
                onChange={(e) => setSearch(e.currentTarget.value)}
              />
            </Flex>
          </>
        }
      >
        {organization && profile ? (
          <UserList
            organization={organization}
            profile={profile}
            search={search}
          ></UserList>
        ) : profile ? (
          <Text>You do not currently belong to an organization.</Text>
        ) : (
          <Loader></Loader>
        )}
      </ScrollablePage>
      {organization && opened && (
        <AddMemberModal
          onClose={() => setOpened(false)}
          organization={organization}
        />
      )}
    </>
  );
}

export default PeoplePage;

const useStyles = createStyles((theme) => ({
  title: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: theme.spacing.md,
  },
}));
