import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export function getAuthorityForActiveAccount(account?: AccountInfo): string {
  const activeAccount = account ?? msalInstance.getActiveAccount();
  let authority = process.env.REACT_APP_AD_AUTHORITY ?? '';
  if (activeAccount !== null) {
    const tokenClaims = activeAccount.idTokenClaims;
    let policy = 'B2C_1_susi';
    if (tokenClaims !== undefined) {
      policy = tokenClaims['tfp'] as string;
    }
    authority += policy;
  } else {
    authority += 'B2C_1_susi';
  }

  return authority;
}
