import { SensorType } from '@barntools/gateway-message-serialization';
import {
  IconAffiliate,
  IconApiApp,
  IconAsteriskSimple,
  IconBrandZapier,
  IconBucket,
  IconCarTurbine,
  IconChartDots,
  IconCloudRain,
  IconDroplet,
  IconFileUnknown,
  IconGrain,
  IconLamp,
  IconOutlet,
  IconQuestionMark,
  IconScale,
  IconSunWind,
  IconTemperature,
  IconTrafficCone,
  IconWind,
  IconWindsock,
  TablerIcon,
} from '@tabler/icons';

export interface IUser {
  id: string;
  name: string;
  email: string;
  phone: string;
  oid?: string;
}

export interface Profile {
  User: IUser;
  IsBtAdmin: boolean;
  Memberships: {
    RoleID: number;
    Organization: IOrganization;
  }[];
}

export interface IUserCollection {
  Users: IUser[];
}

export type IOrganization = {
  id: string;
  name: string;
};

export interface IOrgUsers {
  Users: IAssignedUser[];
}

export type IAssignedUser = {
  User: IUser;
  roleId: number;
};

export interface IAssignedUsers {
  AssignedUsers: IAssignedUser[];
}

export interface IOrganizationCollection {
  Organizations: IOrganization[];
}

export interface IGateway {
  id: string;
  serialNumber: string;
  name: string;
  hasPower: boolean;
  rssi: number;
  lastDataDateTime: string;
  barnStatus: 'Good' | 'Warning' | 'Silent' | 'Emergency';
  organizationId: string;
}

export interface IGatewayCollection {
  Gateways: IGateway[];
  totalCount: number;
  filteredCount: number;
}

export interface IGatewayGroup {
  id: string;
  name: string;
  Gateways: IGateway[];
  AssignedUsers: IAssignedUser[];
}

export interface IGatewayGroupCollection {
  GatewayGroups: IGatewayGroup[];
}

export function getSensorIcon(sensorType: SensorType): TablerIcon {
  return sensorInfo.get(sensorType)?.icon ?? IconQuestionMark;
}
export function getSensorTypeName(sensorType: SensorType): string {
  return sensorInfo.get(sensorType)?.name ?? 'Unknown';
}

const sensorInfo = new Map<SensorType, { name: string; icon: TablerIcon }>([
  [1000, { icon: IconTemperature, name: 'Temperature' }],
  [1005, { icon: IconTemperature, name: 'Outdoor Temperature' }],
  [1010, { icon: IconCloudRain, name: 'Humidity' }],
  [1015, { icon: IconCloudRain, name: 'Outdoor Humidity' }],
  [1110, { icon: IconLamp, name: 'Illumination' }],
  [1182, { icon: IconOutlet, name: 'Wireless Dry Contact' }],
  [1184, { icon: IconBucket, name: 'BinTrac' }],
  [1185, { icon: IconGrain, name: 'Feed' }],
  [1510, { icon: IconOutlet, name: 'Dry Contact' }],
  [2027, { icon: IconDroplet, name: 'Water' }],
  [-1, { icon: IconTrafficCone, name: 'SensorPairingVersionTracker' }],
  [0, { icon: IconFileUnknown, name: 'Unknown' }],
  [100, { icon: IconCarTurbine, name: 'Onboard Equipment' }],
  [1020, { icon: IconAsteriskSimple, name: 'Ammonia' }],
  [1025, { icon: IconBrandZapier, name: 'Outdoor Ammonia' }],
  [1030, { icon: IconAffiliate, name: 'Hydrogen Sulfide' }],
  [1040, { icon: IconChartDots, name: 'Carbon Dioxide' }],
  [1050, { icon: IconScale, name: 'Negative Pressure' }],
  [1150, { icon: IconApiApp, name: 'Disolved Oxygen' }],
  [1160, { icon: IconWind, name: 'Indoor Wind Speed' }],
  [1165, { icon: IconSunWind, name: 'Outdoor Wind Speed' }],
  [1170, { icon: IconWindsock, name: 'Indoor Wind Direction' }],
]);

export interface ISensor {
  id: string;
  name: string;
  sensorType: SensorType;
  sensorStatus: 'Good' | 'Warning' | 'Emergency';
  alertStatus: 'Enabled' | 'Disabled';
  batteryStatus: 'Full' | 'Good' | 'Low' | 'Empty';
  connectionStatus: 'Strong' | 'Good' | 'Fair' | 'Poor' | 'No Connection';
  sensorIndex: number;
  gateway: IGateway;
  order: number;
  lastDataDateTime: string;
}

export interface ISensorCollection {
  Sensors: ISensor[];
}

export interface IAlarm {
  id: string;
  message: string;
  sensor: ISensor;
}

export interface IAlarmCollection {
  Alarms: IAlarm[];
}
