import {
  Alert,
  Button,
  Container,
  Flex,
  Group,
  Loader,
  Modal,
  TextInput,
} from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { useState } from 'react';
import { useAssignGatewayMutation } from '../../../api/organizations';

type Props = {
  onClose: () => void;
  organizationId: string;
};

function AssignGatewayModal({ onClose, organizationId }: Props) {
  const [newGatewayName, setNewGatewayName] = useState('');
  const [newGatewaySerial, setNewGatewaySerial] = useState('');
  const [isInProcess, setIsInProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [assignGateway] = useAssignGatewayMutation();

  const handleSubmit = () => {
    if (newGatewayName.length > 0 && newGatewaySerial.length > 0) {
      setIsInProcess(true);
      setErrorMessage('');
      assignGateway({
        orgId: organizationId,
        gatewayName: newGatewayName,
        gatewaySerial: newGatewaySerial,
      })
        .unwrap()
        .then(
          () => onClose(),
          (e) => setErrorMessage(e.data.message)
        )
        .finally(() => setIsInProcess(false));
    } else {
      if (newGatewayName.length == 0) {
        setErrorMessage('Name is required');
      } else if (newGatewaySerial.length === 0) {
        setErrorMessage('Serial number is required');
      }
    }
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal opened={true} onClose={onClose} title={'Add Barn'}>
      <Flex direction="column" gap="md">
        <TextInput
          aria-label="Barn name"
          placeholder="Barn name"
          size="md"
          data-autofocus
          onChange={(e) => setNewGatewayName(e.target.value)}
        />
        <TextInput
          aria-label="Gateway serial number"
          placeholder="Gateway serial number"
          size="md"
          data-autofocus
          onKeyDown={getHotkeyHandler([['enter', handleSubmit]])}
          onChange={(e) => setNewGatewaySerial(e.target.value)}
        />
      </Flex>
      {errorMessage && (
        <Container p="md">
          <Alert color={'red'}>{errorMessage}</Alert>
        </Container>
      )}
      <Group pt="md" position="right">
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={isInProcess} onClick={handleSubmit} color="dark">
          Add {isInProcess && <Loader size={'sm'}></Loader>}
        </Button>
      </Group>
    </Modal>
  );
}

export default AssignGatewayModal;
