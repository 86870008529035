import { useAccount, useMsal } from '@azure/msal-react';
import {
  Alert,
  Avatar,
  Badge,
  createStyles,
  Menu,
  Space,
  Text,
} from '@mantine/core';
import { IconBuildingCommunity, IconCheck, IconLogout } from '@tabler/icons';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetOrgsQuery } from '../api/organizations';
import { useAppDispatch } from '../app/hooks';
import { RootState } from '../app/store';
import { selectOrganization } from '../features/organizations/organizationsSlice';
import { getAuthorityForActiveAccount } from '../msal';

export default function UserMenu() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const user = useAccount();
  const authority = getAuthorityForActiveAccount();

  const fullName = (user?.name ?? 'Mock User').split(' ');
  const initials = (
    fullName[0].charAt(0) +
    (fullName.length > 1 ? fullName[1].charAt(0) : fullName[0].charAt(1))
  ).toUpperCase();

  const dispatch = useAppDispatch();
  const orgData = useGetOrgsQuery();
  const selectedOrganization = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );

  let selectedOrgId = selectedOrganization?.id;
  const orgMenuItems: ReactNode[] = [];
  if (orgData.error) {
    orgMenuItems.push(
      <Menu.Item key="error">
        <Alert color="red">Error loading Organizations</Alert>
      </Menu.Item>
    );
  } else if (orgData.isLoading) {
    orgMenuItems.push(
      <Menu.Item key="loading" disabled={true}>
        <Text>Loading organizations...</Text>;
      </Menu.Item>
    );
  } else if (orgData.data) {
    if (!selectedOrganization && orgData.data.Organizations.length > 0) {
      selectedOrgId = orgData.data.Organizations[0].id;
    }

    for (let i = 0; i < orgData.data.Organizations.length; i++) {
      const org = orgData.data.Organizations[i];
      orgMenuItems.push(
        <Menu.Item
          key={org.id}
          onClick={() => dispatch(selectOrganization(org))}
          icon={
            org.id === selectedOrgId ? (
              <IconCheck size={14} color={'blue'} />
            ) : (
              <Space w={14} />
            )
          }
        >
          {org.name}
        </Menu.Item>
      );
    }
  }

  return (
    <Menu shadow="md" width={300} position="bottom-end">
      <Menu.Target>
        <Avatar color="blue" radius="xl" className={classes.userMenu}>
          {initials}
        </Avatar>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Switch Organization</Menu.Label>
        {orgMenuItems}
        <Menu.Divider />
        <Menu.Item
          icon={<IconBuildingCommunity size={14} />}
          component="a"
          onClick={() =>
            navigate({
              pathname: `/organizations`,
              search: window.location.search,
            })
          }
        >
          View All Organizations{' '}
          <Badge color={'brand'} variant="filled">
            Admin
          </Badge>
        </Menu.Item>

        <Menu.Divider />

        <Menu.Label>
          {user?.name ?? ''} &middot; {user?.username ?? ''}
        </Menu.Label>
        <Menu.Item
          icon={<IconLogout size={14} />}
          component="a"
          href="/"
          onClick={() =>
            instance.logoutRedirect({
              authority: authority,
              postLogoutRedirectUri: '/login',
            })
          }
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  userMenu: {
    cursor: 'pointer',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));
