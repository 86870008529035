import { SelectItem } from '@mantine/core';

// TODO(ENG-1136): Publish shared library to use here and in org-service
export class Role {
  constructor(
    readonly name: string,
    readonly id: number,
    readonly abilities: Abilities
  ) {}
}

export interface Abilities {
  canCreate: boolean;
  canUpdate: boolean;
  canRead: boolean;
  canDelete: boolean;
}

export type Ability = keyof Abilities;

export const viewerRole = new Role('viewer', 1, {
  canRead: true,
  canCreate: false,
  canUpdate: false,
  canDelete: false,
});

export const managerRole = new Role('manager', 2, {
  canRead: true,
  canCreate: false,
  canUpdate: true,
  canDelete: false,
});

export const adminRole = new Role('admin', 0, {
  canRead: true,
  canCreate: true,
  canUpdate: true,
  canDelete: true,
});

export const roles: Record<number, Role> = {
  0: adminRole,
  1: viewerRole,
  2: managerRole,
};

export const rolesData: SelectItem[] = [
  { label: 'Admin', value: '0' },
  { label: 'Manager', value: '2' },
  { label: 'Viewer', value: '1' },
];
