import { Title } from '@mantine/core';

function Reporting() {
  return (
    <>
      <Title sx={{ flex: 1 }}>Reporting</Title>
      <div>Coming soon</div>
    </>
  );
}

export default Reporting;
