import { createStyles } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';

interface Props {
  // The content that remains visible while scrolling down
  fixedHeaderContent: React.ReactNode;

  // The rest of the content for the page
  children?: React.ReactNode;
}

function ScrollablePage({ children, fixedHeaderContent }: Props) {
  const [scroll] = useWindowScroll();

  const { classes, cx } = useStyles();
  const fixedHeaderClass = cx(classes.fixedHeader, {
    [classes.scrolled]: scroll.y > 0,
  });
  return (
    <div>
      {fixedHeaderContent && (
        <div className={fixedHeaderClass}>{fixedHeaderContent}</div>
      )}
      <div>{children}</div>
    </div>
  );
}

export default ScrollablePage;

const useStyles = createStyles((theme) => ({
  fixedHeader: {
    position: 'sticky',
    top: 'var(--mantine-header-height, 0px)',
    zIndex: 1,
    paddingTop: '8px',
    background: theme.colors.brand[0],
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark'
          ? theme.colors.dark[3]
          : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.md,
  },
}));
