import { rest } from 'msw';
import { db } from './db';

const baseUrl = process.env.REACT_APP_BT_API_BASE_URL;

export const handlers = [
  rest.get(`${baseUrl}profile/:oid`, (req, resolver, ctx) => {
    const oid = req.params.oid as string;
    return resolver(
      ctx.json({
        User: db.User.findFirst({
          where: { oid: { equals: oid } },
        }),
        IsBtAdmin: true,
        Memberships: db.memberships
          .findMany({
            where: {
              User: {
                name: {
                  equals: 'SuperAdmin Dude',
                },
              },
            },
          })
          .map((m) => {
            return {
              Organization: m.Organization,
              RoleID: m.roleId,
            };
          }),
      })
    );
  }),
  rest.get(`${baseUrl}organizations`, (req, resolver, ctx) => {
    return resolver(ctx.json({ Organizations: db.organization.getAll() }));
  }),
  rest.get(`${baseUrl}organizations/:org`, (req, resolver, ctx) => {
    const org = req.params.org as string;
    return resolver(
      ctx.json({
        Organization: db.organization.findFirst({
          where: {
            id: { equals: org },
          },
        }),
      })
    );
  }),
  ...db.organization.toHandlers('rest', baseUrl),
  rest.get(`${baseUrl}organizations/:org/users`, (req, resolver, ctx) => {
    const org = req.params.org as string;
    return resolver(
      ctx.json({
        Users: db.memberships.findMany({
          where: {
            Organization: {
              id: {
                equals: org,
              },
            },
          },
        }),
      })
    );
  }),
  rest.post(
    `${baseUrl}organizations/:org/users`,
    async (req, resolver, ctx) => {
      const orgId = req.params.org as string;
      const json = await req.json();
      const user = db.User.findFirst({
        where: { id: { equals: json.userId } },
      });
      const org = db.organization.findFirst({
        where: { id: { equals: orgId } },
      });
      if (user && org) {
        const result = db.memberships.create({
          User: user,
          Organization: org,
          roleId: json.roleId,
        });
        return resolver(ctx.json(result));
      }
      return resolver();
    }
  ),
  rest.put(
    `${baseUrl}organizations/:org/users/:user`,
    async (req, resolver, ctx) => {
      const orgId = req.params.org as string;
      const userId = req.params.user as string;
      const json = await req.json();
      const org = db.organization.findFirst({
        where: { id: { equals: orgId } },
      });
      if (org) {
        const result = db.memberships.update({
          where: {
            User: { id: { equals: userId } },
          },
          data: {
            roleId: json.roleId,
          },
        });
        return resolver(ctx.json(result));
      }
      return resolver();
    }
  ),
  rest.delete(
    `${baseUrl}organizations/:org/users/:user`,
    async (req, resolver, ctx) => {
      const orgId = req.params.org as string;
      const userId = req.params.user as string;
      const result = db.memberships.delete({
        where: {
          User: { id: { equals: userId } },
          Organization: { id: { equals: orgId } },
        },
      });
      return resolver(ctx.json(result));
    }
  ),
  rest.get(`${baseUrl}gateways`, (req, resolver, ctx) => {
    if (req.url.searchParams.has('orgId')) {
      return resolver(
        ctx.json(
          db.gateway.findMany({
            where: {
              organization: {
                id: {
                  equals: req.url.searchParams.get('orgId') ?? '',
                },
              },
            },
          })
        )
      );
    }

    return resolver(ctx.json({ Gateways: db.gateway.getAll() }));
  }),
  rest.get(`${baseUrl}gateways/:gateway`, (req, resolver, ctx) => {
    const gatewayId = req.params.gateway as string;
    return resolver(
      ctx.json({
        Gateway: db.gateway.findFirst({
          where: {
            serialNumber: {
              equals: gatewayId,
            },
          },
        }),
      })
    );
  }),
  rest.get(`${baseUrl}gateways/:gateway/sensors`, (req, resolver, ctx) => {
    const gatewayId = req.params.gateway as string;
    return resolver(
      ctx.json({
        Sensors: db.sensor.findMany({
          where: {
            gateway: {
              serialNumber: {
                equals: gatewayId,
              },
            },
          },
        }),
      })
    );
  }),
  rest.get(`${baseUrl}gateways/:gateway/users`, (req, resolver, ctx) => {
    const gatewayId = req.params.gateway as string;
    return resolver(
      ctx.json({
        AssignedUsers: db.UserAssignedToGateway.findMany({
          where: {
            gateway: {
              serialNumber: { equals: gatewayId },
            },
          },
        }),
      })
    );
  }),
  rest.post(`${baseUrl}gateways/:gateway/users`, async (req, resolver, ctx) => {
    const gatewayId = req.params.gateway as string;
    const body = await req.json();
    const user = db.User.findFirst({
      where: {
        id: { equals: body.userId },
      },
    });
    const gateway = db.gateway.findFirst({
      where: { serialNumber: { equals: gatewayId } },
    });
    if (user && gateway) {
      return resolver(
        ctx.json({
          AssignedUser: db.UserAssignedToGateway.create({
            roleId: body.roleId,
            User: user,
            gateway: gateway,
          }),
        })
      );
    }

    return resolver();
  }),
  ...db.gateway.toHandlers('rest', baseUrl),
  rest.get(
    `${baseUrl}organizations/:org/gatewayGroups`,
    (req, resolver, ctx) => {
      const org = req.params.org as string;

      return resolver(
        ctx.json({
          GatewayGroups: db.gatewayGroup.findMany({
            where: {
              organization: {
                id: {
                  equals: org,
                },
              },
            },
          }),
        })
      );
    }
  ),
  rest.post(
    `${baseUrl}gatewayGroups/:gatewayGroup/users`,
    async (req, resolver, ctx) => {
      const gatewayGroupId = req.params.gatewayGroup as string;
      const gatewayGroup = db.gatewayGroup.findFirst({
        where: {
          id: { equals: gatewayGroupId },
        },
      });
      const body = await req.json();
      const user = db.User.findFirst({
        where: {
          id: { equals: body.userId },
        },
      });
      if (user && gatewayGroup) {
        db.UserAssignedToGatewayGroup.create({
          gatewayGroup: gatewayGroup,
          User: user,
          roleId: body.roleId,
        });
      }
      return resolver(ctx.json({}));
    }
  ),
  rest.get(`${baseUrl}users`, (req, resolver, ctx) => {
    if (req.url.searchParams.has('orgId')) {
      return resolver(
        ctx.json({
          Users: db.User.findMany({
            where: {
              organizations: {
                id: {
                  equals: req.url.searchParams.get('orgId') ?? '',
                },
              },
            },
          }),
        })
      );
    }

    return resolver(ctx.json({ Users: db.User.getAll() }));
  }),
  ...db.User.toHandlers('rest', baseUrl),

  rest.patch(`${baseUrl}sensors`, async (req, resolver) => {
    const sensorIds = await req.json();
    for (let i = 0; i < sensorIds.length; i++) {
      db.sensor.update({
        where: {
          id: {
            equals: sensorIds[i],
          },
        },
        data: {
          order: i,
        },
      });
    }
    return resolver();
  }),
  rest.get(`${baseUrl}sensors`, (req, resolver, ctx) => {
    if (req.url.searchParams.has('gatewayId')) {
      return resolver(
        ctx.json(
          db.sensor.findMany({
            where: {
              gateway: {
                serialNumber: {
                  equals: req.url.searchParams.get('gatewayId') ?? '',
                },
              },
            },
          })
        )
      );
    }

    return resolver(ctx.json({ Sensors: db.sensor.getAll() }));
  }),
  ...db.sensor.toHandlers('rest', baseUrl),

  rest.get(`${baseUrl}alarm`, (req, resolver, ctx) => {
    if (req.url.searchParams.has('sensorId')) {
      return resolver(
        ctx.json(
          db.alarm.findMany({
            where: {
              sensor: {
                id: {
                  equals: req.url.searchParams.get('sensorId') ?? '',
                },
              },
            },
          })
        )
      );
    }

    return resolver(ctx.json({ Alarms: db.alarm.getAll() }));
  }),
  ...db.alarm.toHandlers('rest', baseUrl),
];
