import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { ButtonStyles } from './styles/ButtonStyles';
import { Colors } from './styles/Colors';
import { NotificationStyles } from './styles/NotificationStyles';
import { TitleStyles } from './styles/TitleStyles';

export function Wrappers({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          components: {
            Button: {
              styles: ButtonStyles,
            },
            Title: {
              styles: TitleStyles,
            },
            Notification: {
              styles: NotificationStyles,
            },
          },
          colors: Colors,
        }}
      >
        <ModalsProvider>
          <NotificationsProvider>{children}</NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  );
}
