import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

export const openCallTreeModal = () =>
  openConfirmModal({
    title: 'Manage Call Tree',
    children: (
      <>
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          [Placeholder for Call Tree Modal]
        </Text>
      </>
    ),
    labels: { confirm: 'Save Changes', cancel: 'Cancel' },
    confirmProps: { color: 'blue' },
    cancelProps: { color: 'gray' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () =>
      showNotification({
        title: 'Call Tree Saved',
        message: 'Call Tree was updated.',
        icon: <IconCheck />,
        color: 'blue',
      }),
  });
