import {
  ActionIcon,
  Center,
  createStyles,
  Flex,
  Group,
  Loader,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { IconChevronLeft } from '@tabler/icons';
import { Link, useParams } from 'react-router-dom';
import { useGetOrgQuery } from '../../api/organizations';
import GatewayGroups from './GatewayGroups';

function OrganizationDetail() {
  const { id: orgId } = useParams<{ id: string }>();
  const orgFetch = useGetOrgQuery(orgId ? orgId : skipToken);
  const { classes } = useStyles();

  if (!orgId) {
    return <Text>Organization not found.</Text>;
  }

  if (orgFetch.error) {
    return <>There was an error loading the data.</>;
  } else if (orgFetch.isLoading) {
    return (
      <Center>
        <Loader></Loader>
      </Center>
    );
  } else if (!orgFetch.data?.Organization) {
    return <>Organization not found.</>;
  }

  const org = orgFetch.data.Organization;

  return (
    <>
      <Flex className={classes.pageHeader}>
        <Group position="left">
          <Tooltip
            label="Back to All Organizations"
            color="dark"
            position="bottom"
          >
            <ActionIcon
              className={classes.backButton}
              variant="subtle"
              aria-label="Back to All Organizations"
              component={Link}
              to={{
                pathname: `/organizations`,
                search: window.location.search,
              }}
            >
              <IconChevronLeft size={24} />
            </ActionIcon>
          </Tooltip>

          <Flex gap="md" pb="sm">
            <Title data-testid="name">{org?.name ?? org?.id}</Title>
          </Flex>
        </Group>
      </Flex>
      <Title order={2} mb={'sm'}>
        Gateway Groups
      </Title>
      <GatewayGroups organizationId={org.id} />
    </>
  );
}

export default OrganizationDetail;

const useStyles = createStyles((theme) => ({
  pageHeader: {
    justifyContent: 'space-between',
    marginBottom: '42px',
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing.sm,
    },
  },

  backButton: {
    position: 'absolute',
    left: '9%',
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
    },

    [theme.fn.smallerThan('lg')]: {
      left: '3%',
    },

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
}));
