import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Loader,
  Select,
  Title,
} from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons';
import { useState } from 'react';
import {
  useGetUsersForGatewayGroupQuery,
  useUnassignUserToGatewayGroupMutation,
  useUpdateUserGatewayGroupRoleMutation,
} from '../../api/assignments';
import { IGatewayGroup } from '../../api/types';
import ConfirmModal from '../../components/ConfirmModal';
import DataTable from '../../components/DataTable/DataTable';
import { rolesData } from '../../Permissions/GatewayRoles';
import AssignUserModal from './AssignUserModal';

type Props = {
  gatewayGroup: IGatewayGroup;
};

function GatewayGroup(props: Props) {
  const gatewayGroupAssignments = useGetUsersForGatewayGroupQuery(
    props.gatewayGroup.id
  );
  const [unassignUser] = useUnassignUserToGatewayGroupMutation();
  const [updateUserRole] = useUpdateUserGatewayGroupRoleMutation();
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
  const [removingUser, setRemovingUser] = useState<{
    id: string;
    name: string;
  }>();

  if (gatewayGroupAssignments.error) {
    return <>There was an error loading the group.</>;
  } else if (gatewayGroupAssignments.isLoading) {
    return (
      <Center>
        <Loader></Loader>
      </Center>
    );
  }

  return (
    <>
      {isAssignUserModalOpen && (
        <AssignUserModal
          gatewayGroup={props.gatewayGroup}
          onClose={() => setIsAssignUserModalOpen(false)}
        />
      )}
      {removingUser && (
        <ConfirmModal
          message={`Remove ${removingUser.name} from the group?`}
          onConfirm={() =>
            unassignUser({
              userId: removingUser.id,
              gatewayGroupId: props.gatewayGroup.id,
            }).then(() => setRemovingUser(undefined))
          }
          onCancel={() => setRemovingUser(undefined)}
        />
      )}
      <Flex gap="md" mb={'sm'} sx={{ alignItems: 'center' }}>
        <Title sx={{ flex: 1 }} order={3}>
          {props.gatewayGroup.name}
        </Title>
        <Button
          leftIcon={<IconPlus />}
          variant="outline"
          color="dark"
          onClick={() => setIsAssignUserModalOpen(true)}
        >
          Assign User
        </Button>
      </Flex>
      <DataTable
        columns={[
          {
            accessor: 'name',
            sortable: true,
            important: true,
            span: 8,
            title: 'Name',
          },
          {
            accessor: 'role',
            sortable: true,
            span: 3,
            title: 'Role',
            render(record) {
              return (
                <Select
                  aria-label="Role"
                  data={rolesData}
                  defaultValue={record.role.toString()}
                  onChange={(e) => {
                    if (e === null) {
                      return;
                    }

                    updateUserRole({
                      userId: record.id,
                      roleId: parseInt(e),
                      gatewayGroupid: props.gatewayGroup.id,
                    });
                  }}
                />
              );
            },
          },
          {
            accessor: 'id',
            sortable: false,
            span: 1,
            title: 'Actions',
            render(record) {
              return (
                <ActionIcon
                  color="red"
                  onClick={() =>
                    setRemovingUser({ id: record.id, name: record.name })
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              );
            },
          },
        ]}
        data={
          gatewayGroupAssignments.data?.AssignedUsers.map((ua) => {
            return {
              id: ua.User.id,
              name: ua.User.name,
              role: ua.roleId,
            };
          }) ?? []
        }
        renderHeaders={true}
        textWhenNoData="No one is assigned"
      ></DataTable>
    </>
  );
}

export default GatewayGroup;
