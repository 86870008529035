import { Button, Menu } from '@mantine/core';
import {
  IconBell,
  IconBellOff,
  IconBellRinging,
  IconChevronDown,
  IconDownload,
  IconPencil,
  IconPhoneCall,
  IconUsers,
} from '@tabler/icons';
import { useState } from 'react';
import { IGateway } from '../../api/types';
import { openBulkThresholdsModal } from './modals/BulkThresholdsModal';
import { openCallTreeModal } from './modals/CallTreeModal';
import { openDisableBarnAlarmModal } from './modals/DisableBarnAlarmModal';
import { openDownloadDataModal } from './modals/DownloadDataModal';
import { EditAccessModal } from './modals/EditAccessModal';
import { EditBarnModal } from './modals/EditBarnModal';
import { openEnableBarnAlarmNotification } from './modals/EnableBarnAlarmNotification';

interface Props {
  gateway?: IGateway;
}

export default function SingleGatewayActionMenu(props: Props) {
  const [isEditBarnModalOpen, setIsEditBarnModalOpen] = useState(false);
  const [isEditAccessModalOpen, setIsEditAccessModalOpen] = useState(false);
  return (
    <>
      {props.gateway && isEditBarnModalOpen && (
        <EditBarnModal
          gateway={props.gateway}
          onClose={() => setIsEditBarnModalOpen(false)}
        />
      )}
      {props.gateway && isEditAccessModalOpen && (
        <EditAccessModal
          gateway={props.gateway}
          onClose={() => setIsEditAccessModalOpen(false)}
        />
      )}
      <Menu
        data-testid="action-menu"
        withinPortal
        disabled={!props.gateway}
        shadow="md"
        width={200}
        position="bottom-end"
      >
        <Menu.Target>
          <Button rightIcon={<IconChevronDown size={14} />}>Actions</Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            data-testid="edit-barn"
            onClick={() => setIsEditBarnModalOpen(true)}
            icon={<IconPencil size={16} />}
          >
            Edit Barn
          </Menu.Item>
          <Menu.Item
            data-testid="edit-access"
            onClick={() => setIsEditAccessModalOpen(true)}
            icon={<IconUsers size={16} />}
          >
            Edit Access
          </Menu.Item>
          <Menu.Item
            onClick={() => openCallTreeModal()}
            icon={<IconPhoneCall size={16} />}
          >
            Manage Call Tree
          </Menu.Item>
          <Menu.Item
            onClick={() => openBulkThresholdsModal()}
            icon={<IconBell size={16} />}
          >
            Set Alert Thresholds
          </Menu.Item>
          <Menu.Item
            onClick={() => openDownloadDataModal()}
            icon={<IconDownload size={16} />}
          >
            Download Barn Data
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={() => openDisableBarnAlarmModal()}
            icon={<IconBellOff size={16} />}
          >
            Disable Alarms
          </Menu.Item>
          <Menu.Item
            onClick={() => openEnableBarnAlarmNotification()}
            icon={<IconBellRinging size={16} />}
          >
            Enable Alarms
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
