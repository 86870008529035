import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

export const openDownloadDataModal = () =>
  openConfirmModal({
    title: 'Download Data',
    children: (
      <>
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          [Placeholder for Data Download Modal -- Coming Soon]
        </Text>
      </>
    ),
    labels: { confirm: 'Send Email with Data', cancel: 'Cancel' },
    confirmProps: { color: 'blue' },
    cancelProps: { color: 'gray' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () =>
      showNotification({
        title: 'Email Sent',
        message: 'Email with your data was sent.',
        icon: <IconCheck />,
        color: 'blue',
      }),
  });
