import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOrganization, Profile } from '../../api/types';

export interface IOrganizationsState {
  selectedOrganization: IOrganization | undefined;
  profile: Profile | undefined;
}

const initialState: IOrganizationsState = {
  selectedOrganization: undefined,
  profile: undefined,
};

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    selectOrganization: (state, action: PayloadAction<IOrganization>) => {
      state.selectedOrganization = action.payload;
    },
    setProfile: (state, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
    },
    clearOrganization: (state) => {
      state.selectedOrganization = undefined;
    },
  },
});

export const { selectOrganization, clearOrganization, setProfile } =
  organizationSlice.actions;

export default organizationSlice.reducer;
