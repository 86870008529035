import {
  Alert,
  Autocomplete,
  Button,
  Container,
  Group,
  Loader,
  Modal,
  Select,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { IOrganization } from '../../api/types';
import { useAddUserToOrgMutation, useGetUsersQuery } from '../../api/users';
import { rolesData } from '../../Permissions/OrganizationRoles';

type Props = {
  organization: IOrganization;
  onClose: () => void;
};

interface DisplayUser {
  label: string;
  value: string;
  userid: string;
}

function AddMemberModal({ onClose, organization }: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const allUsers = useGetUsersQuery();
  const [addUser] = useAddUserToOrgMutation();
  const [users, setUsers] = useState<DisplayUser[]>([]);

  useEffect(() => {
    setUsers(
      (allUsers?.data?.Users ?? [])
        .map((e) => {
          return {
            userid: e.id.toString(),
            label: e.name,
            value: e.name,
          } as DisplayUser;
        })
        .sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0)
    );
  }, [allUsers.data]);

  return (
    <>
      <Modal
        opened={true}
        onClose={() => onClose()}
        title={`Add User to ${organization.name}`}
      >
        {allUsers.isFetching ? (
          <Loader></Loader>
        ) : (
          <>
            <Autocomplete
              label="User"
              placeholder="Select a person to add"
              filter={(value, item) =>
                item.label.toLowerCase().includes(value.toLowerCase())
              }
              onItemSubmit={(e) => {
                setSelectedUserId(e.userid);
              }}
              nothingFound="No matching people"
              data={users}
            />
            <Select
              label="Role"
              data={rolesData}
              defaultValue={'Viewer'}
              onChange={(e) => setSelectedRoleId(e ?? '')}
            />
            {errorMessage && (
              <Container p="md">
                <Alert color={'red'}>{errorMessage}</Alert>
              </Container>
            )}
            <Group pt="md" position="right">
              <Button variant="outline" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                disabled={isAdding}
                color="dark"
                onClick={() => {
                  setIsAdding(true);
                  setErrorMessage('');
                  addUser({
                    userId: selectedUserId,
                    roleId: parseInt(selectedRoleId),
                    orgId: `${organization?.id ?? 0}`,
                  })
                    .unwrap()
                    .then(
                      () => {
                        onClose();
                      },
                      (e) => setErrorMessage(e.data.message)
                    )
                    .finally(() => setIsAdding(false));
                }}
              >
                Add {isAdding && <Loader size={'sm'}></Loader>}
              </Button>
            </Group>
          </>
        )}
      </Modal>
    </>
  );
}

export default AddMemberModal;
