import { Alert, Loader, Stack } from '@mantine/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetAllAlarmsQuery } from '../../api/alarms';
import { getSensorIcon, IAlarm } from '../../api/types';
import { RootState } from '../../app/store';

function Dashboard() {
  const selectedOrganization = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );
  const { data: alarmData, error, isLoading } = useGetAllAlarmsQuery();
  if (error) {
    return (
      <Alert color="dark">Alarm dashboard is not currently available.</Alert>
    );
  } else if (isLoading) {
    return <Loader></Loader>;
  }

  const alarmedGateways = new Map<string, IAlarm[]>();
  if (alarmData !== undefined) {
    for (let i = 0; i < alarmData.length; i++) {
      const alarm = alarmData[i];
      if (
        selectedOrganization !== undefined &&
        alarm.sensor.gateway.organizationId !== selectedOrganization.id
      ) {
        continue;
      }

      if (!alarmedGateways.has(alarm.sensor.gateway.serialNumber)) {
        alarmedGateways.set(alarm.sensor.gateway.serialNumber, []);
      }

      alarmedGateways.get(alarm.sensor.gateway.serialNumber)?.push(alarm);
    }
  }

  const items: JSX.Element[] = [];
  alarmedGateways.forEach((alarms: IAlarm[]) => {
    const gateway = alarms[0].sensor.gateway;
    items.push(
      <Alert color="red" key={gateway.serialNumber}>
        {gateway.name} has {alarms.length} alarms active:
        {alarms.map((alarm) => {
          const icon = getSensorIcon(alarm.sensor.sensorType);
          return (
            <div key={alarm.id}>
              {icon({})} {alarm.message}
            </div>
          );
        })}
        <Link
          to={{
            pathname: `gateways/${gateway.serialNumber}`,
            search: window.location.search,
          }}
        >
          Go to gateway
        </Link>
      </Alert>
    );
  });

  return <Stack>{items}</Stack>;
}

export default Dashboard;
