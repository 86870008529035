import { Button, Container, createStyles, Flex, Title } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconPlus, IconSearch } from '@tabler/icons';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyGetGatewaysByOrgQuery } from '../../api/gateways';
import { IGateway, Profile } from '../../api/types';
import { RootState } from '../../app/store';
import { appConfig } from '../../appConfig';
import { ColumnProps, DataTableSortStatus } from '../../components/DataTable';
import DataTable from '../../components/DataTable/DataTable';
import DataTableHeader from '../../components/DataTable/DataTableHeader';
import ScrollablePage from '../../components/ScrollablePage';
import { SearchInput } from '../../components/SearchInput';
import { defaultOrganization } from '../../profile/helpers';
import BulkGatewayActionMenu from './BulkGatewayActionMenu';
import AssignGatewayModal from './modals/AssignGatewayModal';

type Props = {
  gatewayhook?: typeof useLazyGetGatewaysByOrgQuery;
  itemsPerPage?: number;
};

function GatewayList(props: Props) {
  const selectedOrganization = useSelector(
    (state: RootState) => state.organizations.selectedOrganization
  );
  const profile = useSelector(
    (state: RootState) => state.organizations.profile
  );
  const organization = selectedOrganization ?? defaultOrganization(profile);

  const [getGateways, result] = (
    props.gatewayhook ?? useLazyGetGatewaysByOrgQuery
  )();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isAssignGatewayModalOpen, setIsAssignGatewayModalOpen] =
    useState(false);
  const [filter, setFilter] = useDebouncedState('', 250);
  const [records, setRecords] = useState<IGateway[]>([]);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'lastDataDateTime',
    sortDirection: 'desc',
  });
  const pageSize = props.itemsPerPage ?? 25;
  const [currentPage, setCurrentPage] = useState(0);

  function refetch() {
    getGateways({
      orgIds: organization?.id ? [organization.id] : [],
      orderBy: sortStatus.columnAccessor,
      asc: sortStatus.sortDirection === 'asc',
      search: filter,
      start: currentPage * pageSize,
      length: pageSize,
    }).then((result) => setRecords(result.data?.Gateways ?? []));
  }

  useEffect(() => {
    refetch();
  }, [sortStatus, filter, currentPage]);

  useEffect(() => {
    getGateways({
      orgIds: organization?.id ? [organization.id] : [],
      orderBy: 'lastDataDateTime',
      asc: false,
      search: '',
      start: 0,
      length: pageSize,
    }).then((result) => setRecords(result.data?.Gateways ?? []));
  }, []);

  const paginationControls = [];
  if (result.currentData && result.currentData.filteredCount > pageSize) {
    paginationControls.push(
      <Button
        key={`previous-page`}
        disabled={currentPage == 0}
        onClick={() => setCurrentPage(currentPage - 1)}
        variant={'outline'}
        color={'blue'}
      >
        Previous
      </Button>
    );
    const pageCount = Math.ceil(result.currentData.filteredCount / pageSize);
    for (let i = 0; i < pageCount; i++) {
      paginationControls.push(
        <Button
          key={`page-${i}`}
          onClick={() => setCurrentPage(i)}
          variant={currentPage == i ? 'filled' : 'outline'}
          color={'blue'}
        >
          {i + 1}
        </Button>
      );
    }
    paginationControls.push(
      <Button
        key={`next-page`}
        disabled={currentPage == pageCount - 1}
        onClick={() => setCurrentPage(currentPage + 1)}
        variant={'outline'}
        color={'blue'}
      >
        Next
      </Button>
    );
  }

  const columns: ColumnProps<IGateway>[] = [
    {
      accessor: 'name',
      title: 'Name',
      sortable: true,
      important: true,
      span: 6,
    },
    {
      accessor: 'lastDataDateTime',
      title: 'Updated',
      sortable: true,
      span: 2,
      render: (gateway) => {
        let formattedDataTime = '';
        let lastDataTime = '';
        if (gateway.lastDataDateTime) {
          const dateTime = DateTime.fromISO(gateway.lastDataDateTime);
          lastDataTime = gateway.lastDataDateTime;
          formattedDataTime = dateTime.toRelative({}) ?? '';
        } else {
          formattedDataTime = 'Never';
          lastDataTime = 'No data has been received';
        }

        return (
          <Container title={lastDataTime.toLocaleString()}>
            {formattedDataTime}
          </Container>
        );
      },
    },
    {
      accessor: 'rssi',
      title: 'Connectivity',
      sortable: true,
      span: 2,
      render: (gateway) => rssiToFriendlyText(gateway.rssi),
    },
    {
      accessor: 'hasPower',
      title: 'Powered',
      sortable: true,
      span: 2,
      render: (gateway) => {
        return (
          <Container className={gateway.hasPower ? '' : classes.unpowered}>
            {gateway.hasPower ? 'Powered' : 'No Power'}
          </Container>
        );
      },
    },
  ];

  return (
    <>
      {isAssignGatewayModalOpen && (
        <AssignGatewayModal
          organizationId={organization?.id ?? ''}
          onClose={() => setIsAssignGatewayModalOpen(false)}
        />
      )}
      <ScrollablePage
        fixedHeaderContent={
          <>
            {renderTitleControls(
              profile,
              classes,
              setFilter,
              setIsAssignGatewayModalOpen
            )}
            <DataTableHeader
              columns={columns}
              onSort={setSortStatus}
              sortStatus={sortStatus}
            ></DataTableHeader>
          </>
        }
      >
        <DataTable
          onSort={setSortStatus}
          renderHeaders={false}
          hasError={false}
          isLoading={result.currentData === undefined}
          sortStatus={sortStatus}
          data={records}
          textWhenNoData="You don't currently have access to any barns."
          columns={columns}
          onRowClick={(gateway) =>
            navigate({
              pathname: `${appConfig.navigationPrefix}/gateways/${gateway.serialNumber}`,
              search: window.location.search,
            })
          }
        />
        {paginationControls.length > 0 && (
          <Flex mt={'sm'} gap={'xs'} justify="flex-end">
            {paginationControls}
          </Flex>
        )}
      </ScrollablePage>
    </>
  );
}

export default GatewayList;

function renderTitleControls(
  profile: Profile | undefined,
  classes: Record<'title', string>,
  handleSearchChange: (value: string) => void,
  setIsAssignGatewayModalOpen: (value: boolean) => void
) {
  // TODO: Add checkboxes so multiple barns can be acted on
  const selectedBarns = 0 as number;
  return (
    <Flex gap="md" className={classes.title}>
      <Title sx={{ flex: 1 }}>Barns</Title>
      {(profile?.IsBtAdmin ?? false) && (
        <Button
          leftIcon={<IconPlus />}
          variant="outline"
          color="dark"
          onClick={() => setIsAssignGatewayModalOpen(true)}
        >
          Add Barn
        </Button>
      )}

      {selectedBarns > 0 && <BulkGatewayActionMenu gateways={[]} />}

      <SearchInput
        placeholder="Search Barns"
        width={'10rem'}
        icon={<IconSearch size={14} stroke={1.5} />}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleSearchChange(event.target.value)
        }
      />
    </Flex>
  );
}

function rssiToFriendlyText(rssi: number): string {
  if (rssi == 0) {
    return 'Poor';
  } else if (rssi > -70) {
    return 'Very Good';
  } else if (rssi >= -85) {
    return 'Good';
  } else if (rssi >= -100) {
    return 'Fair';
  } else {
    return 'Poor';
  }
}

const useStyles = createStyles((theme) => ({
  // TODO: Refactor to use a shared style via a new "titleControls" component
  title: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: theme.spacing.md,
  },

  unpowered: {
    color: theme.colors.red[9],
    fontWeight: 700,
  },
}));
