import {
  ActionIcon,
  Button,
  createStyles,
  Flex,
  Group,
  Title,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteOrgMutation, useGetOrgsQuery } from '../../api/organizations';
import { IOrganization } from '../../api/types';
import { RootState } from '../../app/store';
import ConfirmModal from '../../components/ConfirmModal';
import { ColumnProps, DataTableSortStatus } from '../../components/DataTable';
import DataTable from '../../components/DataTable/DataTable';
import DataTableHeader from '../../components/DataTable/DataTableHeader';
import ScrollablePage from '../../components/ScrollablePage';
import { SearchInput } from '../../components/SearchInput';
import CreateOrgModal from './CreateOrgModal';
import UpdateOrgModal from './UpdateOrgModal';

function OrganizationList() {
  const navigate = useNavigate();
  const profile = useSelector(
    (state: RootState) => state.organizations.profile
  );

  const orgData = useGetOrgsQuery();
  const [deleteOrg] = useDeleteOrgMutation();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'name',
    sortDirection: 'asc',
  });
  const [filter, setFilter] = useDebouncedState('', 150);
  const [records, setRecords] = useState<IOrganization[]>([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [updatingOrganization, setUpdatingOrganization] = useState<
    IOrganization | undefined
  >();
  const [deletingOrganization, setDeletingOrganization] = useState<
    IOrganization | undefined
  >();

  const { classes } = useStyles();

  function applySort(data: IOrganization[]) {
    const sorted = sortBy(data, [
      (item) =>
        (
          (item as Record<string, unknown>)[sortStatus.columnAccessor] as string
        ).toLowerCase(),
    ]);
    if (sortStatus.sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }

  useEffect(() => {
    setRecords(applySort(records));
  }, [sortStatus]);

  useEffect(() => {
    if (orgData.data) {
      const data = applySort(orgData.data.Organizations);
      setRecords(data);
    }
  }, [orgData.data]);

  useEffect(() => {
    if (orgData.data) {
      setRecords(
        applySort(
          orgData.data.Organizations.filter(
            (o) => o.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
          )
        )
      );
    }
  }, [filter]);

  const columns: ColumnProps<IOrganization>[] = [
    {
      accessor: 'name',
      title: 'Name',
      sortable: true,
      important: true,
      span: 11,
    },
    {
      accessor: 'actions',
      title: 'Actions',
      sortable: false,
      span: 1,
      render: (organization) =>
        profile &&
        profile.IsBtAdmin && (
          <Group spacing={4} position="right" noWrap>
            <ActionIcon
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setUpdatingOrganization(organization);
              }}
            >
              <IconPencil size={16} />
            </ActionIcon>
            <ActionIcon
              color="red"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setDeletingOrganization(organization);
              }}
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Group>
        ),
    },
  ];

  return (
    <>
      {isCreateModalOpen && (
        <CreateOrgModal onClose={() => setIsCreateModalOpen(false)} />
      )}
      {updatingOrganization && (
        <UpdateOrgModal
          organization={updatingOrganization}
          onClose={() => setUpdatingOrganization(undefined)}
        />
      )}
      {deletingOrganization && (
        <ConfirmModal
          message={`Are you sure you want to delete ${deletingOrganization.name}?`}
          onConfirm={() =>
            deleteOrg(deletingOrganization.id).then(() =>
              setDeletingOrganization(undefined)
            )
          }
          onCancel={() => setDeletingOrganization(undefined)}
        />
      )}
      <ScrollablePage
        fixedHeaderContent={
          <>
            <Flex gap="md" className={classes.title}>
              <Title sx={{ flex: 1 }}>Organizations </Title>
              {profile && profile.IsBtAdmin && (
                <Button
                  leftIcon={<IconPlus size={16} />}
                  variant="outline"
                  color="dark"
                  onClick={() => setIsCreateModalOpen(true)}
                >
                  New Organization
                </Button>
              )}
              <SearchInput
                placeholder="Search Organizations"
                width={'10rem'}
                onChange={(e) => setFilter(e.currentTarget.value)}
              />
            </Flex>
            <DataTableHeader
              columns={columns}
              onSort={setSortStatus}
              sortStatus={sortStatus}
            ></DataTableHeader>
          </>
        }
      >
        <DataTable
          renderHeaders={false}
          isLoading={orgData.isLoading}
          hasError={orgData.error !== undefined}
          onSort={setSortStatus}
          onRowClick={(org) =>
            navigate({
              pathname: `/organizations/${org.id}`,
              search: window.location.search,
            })
          }
          textWhenNoData="You do not currently belong to any organizations."
          sortStatus={sortStatus}
          data={records}
          columns={columns}
        ></DataTable>
      </ScrollablePage>
    </>
  );
}

export default OrganizationList;

const useStyles = createStyles((theme) => ({
  title: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column',
    },
    paddingBottom: theme.spacing.md,
  },
}));
