import { Button, Group, Modal, TextInput } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { useState } from 'react';
import { useCreateOrgMutation } from '../../api/organizations';

type Props = {
  onClose: () => void;
};

function CreateOrgModal({ onClose }: Props) {
  const [newOrgName, setNewOrgName] = useState('');
  const [createOrg] = useCreateOrgMutation();

  const handleSubmit = () => {
    if (newOrgName.length > 0) {
      createOrg(newOrgName);
    }
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal opened={true} onClose={onClose} title={'Create New Organization'}>
      <TextInput
        aria-label="Organization Name"
        label="Organization Name"
        data-autofocus
        onKeyDown={getHotkeyHandler([['enter', handleSubmit]])}
        onChange={(e) => setNewOrgName(e.target.value)}
        value={newOrgName}
      />
      <Group pt="md" position="right">
        <Button color={'gray'} onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="blue">
          Create
        </Button>
      </Group>
    </Modal>
  );
}

export default CreateOrgModal;
