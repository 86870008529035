import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Flex,
  Grid,
  Group,
  Menu,
  Skeleton,
  Stack,
  Text,
  Timeline,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconActivity,
  IconCalendarTime,
  IconChevronLeft,
  IconCirclePlus,
  IconRouter,
} from '@tabler/icons';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetGatewayQuery } from '../../api/gateways';
import { useGetSensorsByGatewayQuery } from '../../api/sensors';
import { ISensor } from '../../api/types';
import { SensorCard } from '../../components/SensorCard/SensorCard';
import SingleGatewayActionMenu from './SingleGatewayActionMenu';

function GatewayDetail() {
  const { id } = useParams<{ id: string }>();
  const gatewayFetch = useGetGatewayQuery(id ?? '');
  const sensorFetch = useGetSensorsByGatewayQuery(id ?? '');
  const [sensors, setSensors] = useState<ISensor[]>([]);
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (sensorFetch.data) {
      const data: ISensor[] = [];
      sensorFetch.data.Sensors.forEach((value) => data.push(value));
      data.sort((a, b) => a.order - b.order);
      setSensors(data);
    } else {
      setSensors([]);
    }
  }, [sensorFetch.data]);

  if (!id) {
    return <Text>Serial number is required.</Text>;
  }

  if (gatewayFetch.error || sensorFetch.error) {
    return <>There was an error loading the data.</>;
  }

  const gateway = gatewayFetch.data?.Gateway;

  // Build the style of the Barn Icon based on the status of the barn
  let statusClasses = [classes.barnStatus, classes.silencedStatus];

  if (gateway?.barnStatus === 'Good') {
    statusClasses = [classes.barnStatus];
  } else if (gateway?.barnStatus === 'Silent') {
    statusClasses = [classes.barnStatus, classes.silencedStatus];
  } else if (gateway?.barnStatus === 'Emergency') {
    statusClasses = [classes.barnStatus, classes.emergencyStatus];
  } else if (gateway?.barnStatus === 'Warning') {
    statusClasses = [classes.barnStatus, classes.warningStatus];
  }

  let sensorElements: ReactElement;
  if (sensorFetch.isLoading) {
    sensorElements = <Skeleton height={120} mt={6} radius="xl" />;
  } else if (sensors.length == 0) {
    sensorElements = <Text>No sensors.</Text>;
  } else {
    const cards = [];
    for (let i = 0; i < sensors.length; i++) {
      const item = sensors[i];
      const sensorItem = (
        <SensorCard
          key={`${item.id}-${item.sensorIndex}`}
          item={item}
          children={undefined}
        />
      );
      cards.push(sensorItem);
    }
    sensorElements = <Stack spacing="sm">{cards}</Stack>;
  }

  return (
    <>
      <Flex className={classes.pageHeader}>
        <Group position="left">
          <Tooltip label="Back to All Barns" color="dark" position="bottom">
            <ActionIcon
              className={classes.backButton}
              variant="subtle"
              aria-label="Back to All Barns"
              component={Link}
              to={{ pathname: `/gateways`, search: window.location.search }}
            >
              <IconChevronLeft size={24} />
            </ActionIcon>
          </Tooltip>

          <Flex gap="md" pb="sm">
            <Tooltip
              label={gateway?.barnStatus}
              color="dark"
              position="bottom"
              openDelay={1500}
              transition="fade"
              transitionDuration={300}
            >
              <Skeleton
                height={55}
                circle
                mb="xl"
                visible={gateway === undefined}
              >
                <Box className={cx(statusClasses)}>
                  <IconRouter size={24} />
                </Box>
              </Skeleton>
            </Tooltip>
            <Stack spacing={0}>
              <Title data-testid="name">
                <Skeleton
                  height={gateway === undefined ? 28 : 'auto'}
                  mt={gateway === undefined ? 6 : 0}
                  width={gateway === undefined ? '100%' : 'auto'}
                  radius={gateway === undefined ? 'xl' : 0}
                  visible={gateway === undefined}
                >
                  {gateway?.name}
                </Skeleton>
              </Title>
              <Text
                fz="sm"
                c="dimmed"
                title="Serial number"
                data-testid="serial-number"
              >
                <Skeleton
                  height={'auto'}
                  mt={6}
                  width="100%"
                  radius="xl"
                  visible={gateway === undefined}
                >
                  {gateway?.serialNumber}
                </Skeleton>
              </Text>
            </Stack>
          </Flex>
        </Group>
        <Group className={classes.controls} spacing="xs">
          <Menu shadow="md" width={200} position="bottom-end">
            <Menu.Target>
              <Button leftIcon={<IconCalendarTime size={16} />}>
                Last 24 Hours
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>[Placeholder for Datepicking]</Menu.Label>
            </Menu.Dropdown>
          </Menu>

          <SingleGatewayActionMenu gateway={gateway} />
        </Group>
      </Flex>

      <Grid grow gutter="sm">
        <Grid.Col sm={12} md={9} orderMd={2}>
          <Title order={4} className={classes.visuallyHidden}>
            Sensors
          </Title>
          {sensorElements}
        </Grid.Col>
        <Grid.Col sm={12} md={3} orderMd={1}>
          <Title order={4}>
            <IconActivity size={18} /> Activity
          </Title>

          <Box pt={'sm'}>
            <Timeline bulletSize={24} lineWidth={2}>
              <Timeline.Item
                bullet={<IconCirclePlus size={12} />}
                title="Coming Soon"
              >
                <Text color="dimmed" size="sm">
                  Notification timeline coming soon.
                </Text>
              </Timeline.Item>
            </Timeline>
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
}

export default GatewayDetail;

const useStyles = createStyles((theme) => ({
  pageHeader: {
    justifyContent: 'space-between',
    marginBottom: '42px',
    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing.sm,
    },
  },

  controls: {
    justifyContent: 'right',
    [theme.fn.smallerThan('md')]: {
      justifyContent: 'center',
    },
  },

  backButton: {
    position: 'absolute',
    left: '9%',
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
    },

    [theme.fn.smallerThan('lg')]: {
      left: '3%',
    },

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  barnStatus: {
    width: '55px',
    height: '55px',
    background: theme.colors.green[3],
    border: '4px solid #FFFFFF',
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    color: 'white',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  warningStatus: {
    background: theme.colors.yellow[2],
    color: 'white',
  },

  silencedStatus: {
    background: theme.colors.brand[3],
    color: 'white',
  },

  emergencyStatus: {
    background: theme.colors.red[6],
    color: 'white',
  },

  visuallyHidden: {
    position: 'absolute',
    left: '-10000px',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
}));
