import { useAccount, useMsal } from '@azure/msal-react';
import {
  Anchor,
  Burger,
  Container,
  createStyles,
  Divider,
  Group,
  Header,
  Paper,
  Text,
  Transition,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { useState } from 'react';
import { getAuthorityForActiveAccount } from '../msal';
import { Logo } from './Logo';
import NavigationLinks, { NavigationItem } from './NavigationLinks';

export default function AppHeaderMobile(props: { links: NavigationItem[] }) {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const user = useAccount();
  const { instance } = useMsal();
  const authority = getAuthorityForActiveAccount();
  const ref = useClickOutside(() => setOpened(false));

  return (
    <div ref={ref}>
      <Header height={HEADER_HEIGHT} mb={120} className={classes.root}>
        <Container className={classes.header}>
          <Group spacing={40}>
            <Anchor href="/">
              <Logo width={142} />
            </Anchor>
          </Group>

          <Burger
            opened={opened}
            onClick={() => setOpened((o: boolean) => !o)}
            className={classes.burger}
            size="sm"
            color="white"
          />
        </Container>
      </Header>
      <Transition transition="slide-down" duration={200} mounted={opened}>
        {(styles) => (
          <Paper
            className={classes.dropdown}
            withBorder
            shadow={'xl'}
            style={styles}
          >
            <NavigationLinks
              links={props.links}
              onClick={() => setOpened(false)}
            />
            <Divider my="sm" />
            <Container className={classes.userDetails}>
              <Text fz="sm" c="dimmed" fw={500}>
                {user?.name ?? ''}
              </Text>
              <Text c="dimmed" fz="sm">
                {user?.username ?? ''}
              </Text>
            </Container>
            <a
              href={'/'}
              className={classes.link}
              onClick={() =>
                instance.logoutRedirect({
                  authority: authority,
                  postLogoutRedirectUri: '/login',
                })
              }
            >
              Logout
            </a>
          </Paper>
        )}
      </Transition>
    </div>
  );
}

const HEADER_HEIGHT = 72;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 12,
    backgroundColor: '#071939',
  },

  dropdown: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 2,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  userDetails: {
    padding: theme.spacing.md,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'left',
    height: '100%',
    maxWidth: '80%',

    [theme.fn.smallerThan('lg')]: {
      maxWidth: '90%',
    },

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    margin: 'auto 10px',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 6px',
    borderRadius: 0,
    textDecoration: 'none',
    color: theme.colors.gray[4],
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    borderWidth: '3px 0',
    borderColor: 'transparent',
    borderStyle: 'solid',

    '&:hover': {
      color: 'white',
      borderBottomColor: 'rgba(256,256,256,.5)',
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
      color: theme.colors.gray[7],
      borderWidth: '0',

      '&:hover': {
        color: theme.colors.gray[9],
        backgroundColor: theme.colors.gray[0],
      },
    },
  },

  linkActive: {
    '&, &:hover': {
      color: 'white',
      borderBottomColor: theme.colors.red[6],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,

      '&, &:hover': {
        color: theme.colors.red[6],
      },
    },
  },
}));
