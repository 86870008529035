import { SensorType } from '@barntools/gateway-message-serialization';
import { faker } from '@faker-js/faker';
import { getSensorTypeName } from '../api/types';
import { adminRole, managerRole } from '../Permissions/OrganizationRoles';
import { db } from './db';

export function initializeMockData() {
  const barnToolsOrg = mockOrganization('BarnTools', 10, 100, 'Hogs');
  const orgs = [
    barnToolsOrg,
    mockOrganization('Sanderson Farms', 20, 10, 'Poultry'),
  ];
  const superAdmin = db.User.create({
    name: 'SuperAdmin Dude',
    email: 'admin@barntools.com',
    oid: 'mock-use-oid',
    organizations: orgs,
  });

  mockGateway(-1, barnToolsOrg, superAdmin, 'Hogs', 'browser-test-gateway');

  db.memberships.create({
    Organization: orgs[0],
    User: superAdmin,
    roleId: 0,
  });

  db.memberships.create({
    Organization: orgs[1],
    User: superAdmin,
    roleId: 0,
  });
}

type AnimalType = 'Hogs' | 'Poultry';

const imageKeywords = {
  Hogs: ['pig', 'hog', 'piglet', 'sow'],
  Poultry: ['chicken', 'poultry', 'chick', 'rooster', 'egg'],
};

function mockOrganization(
  name: string,
  gatewayCount: number,
  employees: number,
  animalType: AnimalType
) {
  const org = db.organization.create({
    id: name.toLowerCase().replaceAll(' ', '-'),
    name: name,
  });

  let user;
  for (let i = 0; i < employees; i++) {
    const u = db.User.create({
      organizations: [org],
    });
    if (!user) {
      user = u;
    }
    db.memberships.create({
      Organization: org,
      User: u,
      roleId: i == 0 ? adminRole.id : managerRole.id,
    });
  }

  const gateways = [];
  for (let i = 0; i < gatewayCount; i++) {
    gateways.push(mockGateway(i, org, user, animalType));
  }
  db.gatewayGroup.create({
    organization: org,
    name: 'All Gateways',
    gateways: gateways,
  });

  return org;
}

function mockGateway(
  i: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  org: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any,
  animalType: AnimalType,
  serialNumber?: string
) {
  const keywords = imageKeywords[animalType];
  const gatewayName = `${animalType} ${i + 1}`;
  const gateway = db.gateway.create({
    serialNumber:
      serialNumber ?? gatewayName.toLowerCase().replaceAll(' ', '-'),
    name: gatewayName,
    thumbnail: faker.image.unsplash.image(
      250,
      100,
      keywords[i % keywords.length]
    ),
    organization: org,
    organizationId: org.id,
    manager: user,
  });
  const sensorCount = faker.datatype.number({ min: 1, max: 5 });
  const sensorCountByType = new Map<SensorType, number>();
  for (let j = 0; j < sensorCount; j++) {
    const sensor = db.sensor.create({
      name: `Sensor ${j + 1}`,
      gateway: gateway,
      order: j,
    });
    const count = sensorCountByType.get(sensor.sensorType);
    let sensorName = getSensorTypeName(sensor.sensorType);
    if (!count) {
      sensorCountByType.set(sensor.sensorType, 1);
    } else {
      sensorCountByType.set(sensor.sensorType, count + 1);
      sensorName += ' ' + (count + 1).toString();
    }
    sensor.name = sensorName;
    if (faker.datatype.boolean()) {
      db.alarm.create({
        sensor: sensor,
        message: `${sensor.name} has exceeded a threshold`,
      });
    }
  }

  return gateway;
}
