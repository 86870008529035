import { IOrganization, Profile } from '../api/types';

export function defaultOrganization(
  profile?: Profile
): IOrganization | undefined {
  if (!profile) {
    return undefined;
  }

  if (profile.Memberships.length === 0) {
    return undefined;
  }

  return profile.Memberships[0].Organization;
}
