import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { getAuthorityForActiveAccount, msalInstance } from '../msal';
import { barntoolsConfig } from '../msalConfig';

export const barnToolsApi = createApi({
  reducerPath: 'barnToolsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BT_API_BASE_URL,
    prepareHeaders: async (headers) => {
      const account = msalInstance.getActiveAccount();
      if (account !== null) {
        const authority = getAuthorityForActiveAccount(account);
        try {
          const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes: barntoolsConfig.scopes,
            account: account,
            forceRefresh: false,
            authority: authority,
          });
          const token = tokenResponse.accessToken;
          if (token) {
            headers.set('authorization', `Bearer ${token}`);
          }
        } catch (e) {
          await msalInstance.acquireTokenRedirect({
            scopes: barntoolsConfig.scopes,
            account: account,
            authority: authority,
          });
        }
      }

      return headers;
    },
  }),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: 30,
  endpoints: () => ({}),
});
