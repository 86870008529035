import { Button, Group, Loader, Modal, Text } from '@mantine/core';
import { useState } from 'react';

type Props = {
  message: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};

function ConfirmModal({ message, onConfirm, onCancel }: Props) {
  const [isConfirming, setIsConfirming] = useState(false);
  return (
    <Modal opened={true} onClose={onCancel} withCloseButton={false}>
      <Text>{message}</Text>
      <Group pt="md" position="right">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          color="dark"
          disabled={isConfirming}
          onClick={() => {
            setIsConfirming(true);
            onConfirm().then(() => setIsConfirming(false));
          }}
        >
          Confirm {isConfirming && <Loader size={'sm'}></Loader>}
        </Button>
      </Group>
    </Modal>
  );
}

export default ConfirmModal;
