import { MantineTheme, TitleStylesParams } from '@mantine/core';

export const TitleStyles = (theme: MantineTheme, params: TitleStylesParams) => {
  let size = 30;

  switch (params.element) {
    case 'h1':
      size = 30;
      break;
    case 'h2':
      size = 26;
      break;
    case 'h3':
      size = 22;
      break;
    case 'h4':
      size = 18;
      break;
    case 'h5':
      size = 18;
      break;
    case 'h6':
      size = 18;
      break;
  }

  return {
    root: {
      fontWeight: 600,
      fontSize: `${size}px`,
      lineHeight: `${size + size * 0.25}px`,
      color: theme.colors.brand[9],
      minWidth: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      [theme.fn.smallerThan('md')]: {
        fontSize: `${size * 0.85}px`,
        lineHeight: `${size * 0.85 + size * 0.25}px`,
        maxWidth: '500px',
        minWidth: 'auto',
      },

      [theme.fn.smallerThan('sm')]: {
        maxWidth: '300px',
        minWidth: 'auto',
      },
    },
  };
};
