import {
  Anchor,
  Autocomplete,
  Container,
  createStyles,
  Group,
  Header,
} from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useLazyGetAllGatewaysQuery } from '../api/gateways';
import { Logo } from './Logo';
import NavigationLinks, { NavigationItem } from './NavigationLinks';
import UserMenu from './UserMenu';

export default function AppHeader(props: { links: NavigationItem[] }) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [getAllGateways, allGateways] = useLazyGetAllGatewaysQuery();
  const [searchValue, setSearchValue] = useDebouncedState('', 150);

  return (
    <Header height={HEADER_HEIGHT} mb={120} className={classes.root}>
      <Container className={classes.header}>
        <Group spacing={40}>
          <Anchor href="/">
            <Logo width={142} />
          </Anchor>

          <Group spacing={12}>
            <NavigationLinks links={props.links} />
          </Group>
        </Group>
        <Group spacing={12}>
          <Autocomplete
            className={classes.search}
            placeholder="Search Barns"
            icon={<IconSearch size={16} stroke={2} color={'white'} />}
            variant="unstyled"
            radius="xl"
            onFocus={() => getAllGateways(undefined, true)}
            filter={(value, item) =>
              item.label.toLowerCase().includes(value.toLowerCase())
            }
            onItemSubmit={(e) => {
              setSearchValue('');
              navigate({
                pathname: `/gateways/${e.value}`,
                search: window.location.search,
              });
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e)}
            nothingFound="No matching barns"
            data={
              allGateways.data?.Gateways?.map((g) => {
                return { value: g.serialNumber, label: g.name };
              }) ?? []
            }
          />
          <UserMenu />
        </Group>
      </Container>
    </Header>
  );
}

const HEADER_HEIGHT = 72;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 12,
    backgroundColor: '#071939',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'left',
    height: '100%',
    maxWidth: '80%',

    [theme.fn.smallerThan('lg')]: {
      maxWidth: '90%',
    },

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
    },
  },

  search: {
    backgroundColor: 'rgba(256,256,256,.15)',
    color: 'white',
    borderRadius: theme.radius.xl,
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },

    input: {
      color: 'white',
    },
  },
}));
