import { faker } from '@faker-js/faker';
import {
  ActionIcon,
  Badge,
  Box,
  Card,
  CardProps,
  Container,
  createStyles,
  Group,
  Menu,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  IconAntennaBars2,
  IconAntennaBars3,
  IconAntennaBars4,
  IconAntennaBars5,
  IconAntennaBarsOff,
  IconBattery,
  IconBattery1,
  IconBattery3,
  IconBattery4,
  IconBell,
  IconBellOff,
  IconBellRinging,
  IconDotsVertical,
  IconDownload,
  IconPencil,
} from '@tabler/icons';
import { DateTime } from 'luxon';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getSensorIcon, ISensor } from '../../api/types';
import { openBulkThresholdsModal } from '../../features/gateways/modals/BulkThresholdsModal';
import { openDownloadDataModal } from '../../features/gateways/modals/DownloadDataModal';
import { openDisableSensorAlarmModal } from './DisableSensorAlarmModal';
import { openEditSensorModal } from './EditSensorModal';
import { openEnableSensorAlarmNotification } from './EnableSensorAlarmNotification';

const useStyles = createStyles((theme) => ({
  sensorStatus: {
    width: '50px',
    height: '50px',
    background: theme.colors.brand[0],
    color: theme.colors.green[3],
    border: '4px solid #FFFFFF',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '-10px',
  },

  warningStatus: {
    background: theme.colors.yellow[2],
    color: 'white',
  },

  emergencyStatus: {
    background: theme.colors.red[6],
    color: 'white',
  },
}));

interface SensorCardProps extends CardProps {
  item: ISensor;
}

export function SensorCard({ item, ...others }: SensorCardProps) {
  const { classes, cx } = useStyles();
  const icon = getSensorIcon(item.sensorType);

  // Build the style of the Barn Icon based on the status of the barn
  let statusClasses = [classes.sensorStatus];

  if (item?.sensorStatus === 'Good') {
    statusClasses = [classes.sensorStatus];
  } else if (item?.sensorStatus === 'Emergency') {
    statusClasses = [classes.sensorStatus, classes.emergencyStatus];
  } else if (item?.sensorStatus === 'Warning') {
    statusClasses = [classes.sensorStatus, classes.warningStatus];
  }

  // Build Disable Alert badge
  let alertsDisabledBadge = <></>;

  if (item.alertStatus === 'Disabled') {
    alertsDisabledBadge = (
      <Badge color="red" variant="filled">
        Alarms Disabled
      </Badge>
    );
  }

  // Build Battery Status Icon
  let batteryStatusIcon = <IconBattery4 size={18} color="gray" />;

  if (item.batteryStatus === 'Good') {
    batteryStatusIcon = <IconBattery3 size={18} color="gray" />;
  } else if (item.batteryStatus === 'Low') {
    batteryStatusIcon = <IconBattery1 size={18} color="orange" />;
  } else if (item.batteryStatus === 'Empty') {
    batteryStatusIcon = <IconBattery size={18} color="red" />;
  }

  // Build Connection Status Icon
  let connectionStatusIcon = <IconAntennaBars5 size={18} color="gray" />;

  if (item.connectionStatus === 'Good') {
    connectionStatusIcon = <IconAntennaBars4 size={18} color="gray" />;
  } else if (item.connectionStatus === 'Fair') {
    connectionStatusIcon = <IconAntennaBars3 size={18} color="gray" />;
  } else if (item.connectionStatus === 'Poor') {
    connectionStatusIcon = <IconAntennaBars2 size={18} color="orange" />;
  } else if (item.connectionStatus === 'No Connection') {
    connectionStatusIcon = <IconAntennaBarsOff size={18} color="red" />;
  }

  const formattedDataTime = DateTime.fromISO(item.lastDataDateTime).toRelative({
    unit: 'minutes',
  });

  return (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      key={item.id + item.sensorIndex}
      {...others}
    >
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Group position="left">
            <Tooltip
              label={item.sensorStatus}
              color="dark"
              position="bottom"
              openDelay={1500}
              transition="fade"
              transitionDuration={300}
            >
              <Box className={cx(statusClasses)}>{icon({})}</Box>
            </Tooltip>
            <Stack spacing={0}>
              <Text weight={700}>{item.name}</Text>
              <Text fz="sm" c="dimmed">
                Last updated {formattedDataTime}
              </Text>
            </Stack>
          </Group>

          <Group position="right" spacing="xs">
            {alertsDisabledBadge}

            <Tooltip
              label={item.connectionStatus}
              color="dark"
              position="bottom"
              openDelay={700}
              transition="fade"
              transitionDuration={300}
            >
              <ActionIcon variant="transparent" sx={{ padding: '0' }}>
                {connectionStatusIcon}
              </ActionIcon>
            </Tooltip>

            <Tooltip
              label={item.batteryStatus}
              color="dark"
              position="bottom"
              openDelay={700}
              transition="fade"
              transitionDuration={300}
            >
              <ActionIcon variant="transparent" sx={{ padding: '0' }}>
                {batteryStatusIcon}
              </ActionIcon>
            </Tooltip>
            <Menu withinPortal shadow="md" width={200} position="bottom-end">
              <Menu.Target>
                <ActionIcon>
                  <IconDotsVertical size={16} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => openEditSensorModal()}
                  icon={<IconPencil size={16} />}
                >
                  Rename Sensor
                </Menu.Item>
                <Menu.Item
                  onClick={() => openBulkThresholdsModal()}
                  icon={<IconBell size={16} />}
                >
                  Set Alert Thresholds
                </Menu.Item>
                <Menu.Item
                  onClick={() => openDownloadDataModal()}
                  icon={<IconDownload size={16} />}
                >
                  Download Sensor Data
                </Menu.Item>
                <Menu.Divider />
                {item.alertStatus === 'Enabled' ? (
                  <Menu.Item
                    onClick={() => openDisableSensorAlarmModal()}
                    icon={<IconBellOff size={16} />}
                  >
                    Disable Alarms
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    onClick={() => openEnableSensorAlarmNotification()}
                    icon={<IconBellRinging size={16} />}
                  >
                    Enable Alarms
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Card.Section>

      <Card.Section mt="sm">
        <Container maw={'100%'}>
          <ResponsiveContainer width="100%" height={230}>
            <LineChart
              width={100}
              height={120}
              data={Array.from(Array(10)).map((e, i) => {
                return {
                  time: DateTime.now()
                    .minus({ hours: 9 - i })
                    .toFormat('h:mma')
                    .toLowerCase(),
                  temperature: `${faker.datatype.number({
                    min: 32,
                    max: 90,
                  })}`,
                };
              })}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="time" />
              <YAxis dataKey="temperature" />
              <ChartTooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line
                type="linear"
                dot={false}
                dataKey="temperature"
                unit={'F'}
                stroke="#ff7300"
                yAxisId={0}
              />
              <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} />
            </LineChart>
          </ResponsiveContainer>
        </Container>
      </Card.Section>
    </Card>
  );
}
