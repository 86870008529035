import { Code, Text, TextInput } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

export const openEditSensorModal = () =>
  openConfirmModal({
    title: 'Edit Sensor',
    children: (
      <>
        <TextInput
          label="Sensor Name"
          placeholder="Sensor Name"
          data-autofocus
        />
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          Sensor Serial Number: <Code>1023345554949505</Code>
        </Text>
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          [TODO: Implement functionality]
        </Text>
      </>
    ),
    labels: { confirm: 'Save Changes', cancel: 'Cancel' },
    confirmProps: { color: 'blue' },
    cancelProps: { color: 'gray' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () =>
      showNotification({
        title: 'Sensor Changes Saved',
        message: 'Sensor details have been updated.',
        icon: <IconCheck />,
        color: 'blue',
      }),
  });
