import { MantineTheme, NotificationStylesParams } from '@mantine/core';

export const NotificationStyles = (
  theme: MantineTheme,
  params: NotificationStylesParams
) => {
  const btBlue = theme.colors.blue[5];
  const btGreen = theme.colors.green[3];
  const btRed = theme.colors.red[6];
  const btYellow = theme.colors.yellow[2];
  const btMedText = theme.colors.brand[7];

  let bg = params.color;
  let color = theme.white;

  switch (params.color) {
    case 'gray':
      bg = btMedText;
      break;
    case 'white':
      bg = theme.white;
      color = btMedText;
      break;
    case undefined:
      bg = btBlue;
      break;
    case 'blue':
      bg = btBlue;
      break;
    case 'red':
      bg = btRed;
      break;
    case 'orange':
    case 'yellow':
      bg = btYellow;
      break;
    case 'green':
      bg = btGreen;
      break;
    case 'dark':
      bg = btMedText;
      break;
  }

  return {
    root: {
      backgroundColor: bg,
      borderColor: bg,

      '.mantine-Notification-icon': { backgroundColor: 'inherit' },

      '&::before': { backgroundColor: theme.white },
    },

    title: { color: color },
    description: { color: color },
    closeButton: {
      color: color,
      '&:hover': { backgroundColor: theme.fn.darken(bg, 0.3) },
    },
  };
};
