import { useMsal } from '@azure/msal-react';
import { Button, createStyles, Paper, TextInput, Title } from '@mantine/core';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',
    backgroundImage: 'url("red barn.jpg")',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    '> *': {
      gridColumn: 1 / -1,
      gridRow: 1 / -1,
    },
  },

  form: {
    backgroundColor: '#ffffffd4',
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    width: 120,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export function LoginPage() {
  const { classes } = useStyles();
  const [email, setEmail] = useState('');
  const { instance } = useMsal();

  const policy = email.endsWith('@barntools.com')
    ? 'B2C_1_Internal_Signin'
    : email.indexOf('@') !== -1
    ? 'B2C_1_susi'
    : 'B2C_1_Phone';

  const authority = process.env.REACT_APP_AD_AUTHORITY + policy;

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title
          order={2}
          className={classes.title}
          align="center"
          mt="md"
          mb={50}
        >
          Welcome to BarnTools
        </Title>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            instance.loginRedirect({
              prompt: 'select_account',
              loginHint: email,
              authority: authority,
              scopes: [],
              redirectStartPage: '/',
            });
            return false;
          }}
        >
          <TextInput
            aria-label="Email or phone number"
            placeholder="Email or phone number"
            size="md"
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button fullWidth mt="xl" size="md" type="submit">
            Login
          </Button>
        </form>
      </Paper>
    </div>
  );
}
