import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { msalInstance } from './msal';
import reportWebVitals from './reportWebVitals';
import { Wrappers } from './Wrappers';

const params = new URLSearchParams(window.location.search);
const useMockServiceWorker = params.has('msw');
if (useMockServiceWorker) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Wrappers>
        <App useMockServiceWorker={useMockServiceWorker} />
      </Wrappers>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
