import {
  Alert,
  Autocomplete,
  Button,
  Container,
  Group,
  Loader,
  Modal,
  Select,
  Text,
} from '@mantine/core';
import { IconUserPlus, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useAssignUserToGatewayMutation,
  useGetUsersForGatewayQuery,
  useUnassignUserToGatewayMutation,
  useUpdateUserGatewayRoleMutation,
} from '../../../api/assignments';
import { useGetGatewayGroupsQuery } from '../../../api/gatewayGroups';
import { IGateway } from '../../../api/types';
import { useGetUsersByOrgQuery } from '../../../api/users';
import { rolesData } from '../../../Permissions/GatewayRoles';

type Props = {
  gateway: IGateway;
  onClose: () => void;
};

interface DisplayUser {
  label: string;
  value: string;
  userid: string;
}

export function EditAccessModal({ onClose, gateway }: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const allUsers = useGetUsersByOrgQuery(gateway.organizationId);
  const assignedUsers = useGetUsersForGatewayQuery(gateway.serialNumber);
  const gatewayGroups = useGetGatewayGroupsQuery({
    organizationId: gateway.organizationId,
    forGateway: gateway.id,
  });
  const [assignUser] = useAssignUserToGatewayMutation();
  const [unassignUser] = useUnassignUserToGatewayMutation();
  const [updateRole] = useUpdateUserGatewayRoleMutation();
  const [users, setUsers] = useState<DisplayUser[]>([]);

  const isFetchingFirstResult = [gatewayGroups, allUsers, assignedUsers].some(
    (query) => query.isFetching && !query.data
  );

  useEffect(() => {
    setUsers(
      (allUsers?.data?.Users ?? [])
        .map((e) => {
          return {
            userid: e.User.id.toString(),
            label: e.User.name,
            value: e.User.name,
          } as DisplayUser;
        })
        .sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? 0)
    );
  }, [allUsers.data]);
  return (
    <>
      <Modal
        opened={true}
        size="auto"
        onClose={() => onClose()}
        title={`Manage Access to ${gateway.name}`}
      >
        {isFetchingFirstResult ? (
          <Loader></Loader>
        ) : (
          <>
            <hr />
            <Group>
              <IconUserPlus />
              <Autocomplete
                data-testid="new-user"
                aria-label="User"
                placeholder="Select a person to add"
                filter={(value, item) =>
                  item.label.toLowerCase().includes(value.toLowerCase())
                }
                onItemSubmit={(e) => {
                  setSelectedUserId(e.userid);
                }}
                nothingFound="No matching people"
                data={users}
              />
              <Select
                data-testid="new-role"
                aria-label="Role"
                data={rolesData}
                defaultValue={'Viewer'}
                onChange={(e) => setSelectedRoleId(e ?? '')}
              />
              <Button
                data-testid="add-user"
                size="sm"
                disabled={isAdding}
                color="dark"
                onClick={() => {
                  setIsAdding(true);
                  setErrorMessage('');
                  assignUser({
                    userId: selectedUserId,
                    roleId: parseInt(selectedRoleId),
                    gatewaySerial: gateway.serialNumber,
                  })
                    .unwrap()
                    .catch((e) => setErrorMessage(e.data.message))
                    .finally(() => setIsAdding(false));
                }}
              >
                Add {isAdding && <Loader size={'sm'}></Loader>}
              </Button>
            </Group>
            <hr />
            {assignedUsers.data?.AssignedUsers &&
            assignedUsers.data.AssignedUsers.length > 0 ? (
              <Container>
                {assignedUsers.data?.AssignedUsers?.map((user) => {
                  return (
                    <div key={user.User.id}>
                      <Group grow>
                        <Text>{user.User.name}</Text>

                        <Select
                          data={rolesData}
                          withinPortal={true}
                          onChange={(e) => {
                            if (e === null) {
                              return;
                            }

                            updateRole({
                              gatewaySerial: gateway.serialNumber,
                              roleId: parseInt(e),
                              userId: user.User.id,
                            });
                          }}
                          value={`${user.roleId}` ?? 'no role assigned'}
                        />

                        <Group position="right">
                          <IconX
                            data-testid="remove-user"
                            color="red"
                            onClick={() =>
                              unassignUser({
                                userId: user.User.id,
                                gatewaySerial: gateway.serialNumber,
                              })
                            }
                          />
                        </Group>
                      </Group>
                      <hr />
                    </div>
                  );
                })}
              </Container>
            ) : (
              <>
                No one is currently directly assigned.
                <hr />
              </>
            )}

            {gatewayGroups.data?.GatewayGroups.map((gg) => {
              return (
                <Text c="dimmed" size={'sm'} key={gg.id}>
                  This barn is also accessible via the{' '}
                  <Link
                    to={{
                      pathname: `/organizations/${gateway.organizationId}/`,
                      hash: '#groups',
                      search: window.location.search,
                    }}
                  >
                    {gg.name}
                  </Link>{' '}
                  group.
                </Text>
              );
            })}
            {errorMessage && (
              <Container p="md">
                <Alert color={'red'}>{errorMessage}</Alert>
              </Container>
            )}
            <Group pt="md" position="right">
              <Button variant="outline" onClick={() => onClose()}>
                Close
              </Button>
            </Group>
          </>
        )}
      </Modal>
    </>
  );
}
