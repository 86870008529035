import { ActionIcon, Menu, Select } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { IAssignedUser, IOrganization, Profile } from '../../api/types';
import {
  useGetUsersByOrgQuery,
  useRemoveUserFromOrgMutation,
  useUpdateUserRoleMutation,
} from '../../api/users';
import ConfirmModal from '../../components/ConfirmModal';
import { ColumnProps, DataTableSortStatus } from '../../components/DataTable';
import DataTable from '../../components/DataTable/DataTable';
import { roles, rolesData } from '../../Permissions/OrganizationRoles';

interface Props {
  organization: IOrganization;
  profile: Profile;
  search: string;
}

function UserList({ organization, profile, search }: Props) {
  const { data, error, isLoading } = useGetUsersByOrgQuery(organization.id);
  const [updateUserRole] = useUpdateUserRoleMutation();
  const [removeUser] = useRemoveUserFromOrgMutation();
  const [removingUser, setRemovingUser] = useState<IAssignedUser | undefined>();
  const [records, setRecords] = useState<(IAssignedUser & { id: string })[]>(
    []
  );
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'User.name',
    sortDirection: 'asc',
  });

  function applySort(data: (IAssignedUser & { id: string })[]) {
    const sorted = sortBy(data, sortStatus.columnAccessor);
    if (sortStatus.sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }

  function exposeId(
    users: IAssignedUser[]
  ): (IAssignedUser & { id: string })[] {
    return users.map((o) => {
      return { ...o, id: o.User.id };
    });
  }

  useEffect(() => {
    setRecords(applySort(records));
  }, [sortStatus]);

  useEffect(() => {
    if (data) {
      const sorted = applySort(exposeId(data.Users));
      setRecords(sorted);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setRecords(
        applySort(
          exposeId(
            data.Users.filter(
              (o) =>
                o.User.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
            )
          )
        )
      );
    }
  }, [search]);

  const columns: ColumnProps<IAssignedUser>[] = [
    {
      accessor: 'User.name',
      title: 'Name',
      sortable: true,
      important: true,
      span: 4,
    },
    {
      accessor: 'User.email',
      title: 'Email',
      sortable: true,
      span: 4,
    },
    {
      accessor: 'roleId',
      title: 'Role',
      sortable: true,
      span: 3,
      render: (user) => {
        return (
          <Select
            readOnly={
              profile?.Memberships.some(
                (m) =>
                  m.Organization.id === organization?.id &&
                  roles[m.RoleID].abilities.canUpdate
              ) === false
            }
            data={rolesData}
            withinPortal={true}
            onChange={(e) => {
              if (e === null) {
                return;
              }

              updateUserRole({
                userId: user.User.id,
                orgId: organization.id,
                roleId: parseInt(e),
              });
            }}
            value={`${user.roleId}` ?? 'no role assigned'}
          />
        );
      },
    },
    {
      accessor: 'actions',
      title: 'Actions',
      sortable: false,
      span: 1,
      render: (user) =>
        profile?.Memberships.some(
          (m) =>
            m.Organization.id === organization?.id &&
            roles[m.RoleID].abilities.canDelete
        ) === false ? (
          <></>
        ) : (
          <Menu withArrow withinPortal>
            <Menu.Target>
              <ActionIcon>
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                color="red"
                icon={<IconTrash size={14} />}
                onClick={() => {
                  setRemovingUser(user);
                }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
    },
  ];

  return (
    <>
      {removingUser && (
        <ConfirmModal
          message={`Remove ${removingUser.User.name} from the organization?`}
          onConfirm={() =>
            removeUser({
              userId: removingUser.User.id,
              orgId: organization.id,
            }).then(() => setRemovingUser(undefined))
          }
          onCancel={() => setRemovingUser(undefined)}
        />
      )}
      <DataTable
        data={records}
        renderHeaders={true}
        isLoading={isLoading}
        hasError={error !== undefined}
        onSort={setSortStatus}
        sortStatus={sortStatus}
        textWhenNoData="No other users in your organization."
        columns={columns}
      ></DataTable>
    </>
  );
}

export default UserList;
