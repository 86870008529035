import { Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconBellRinging } from '@tabler/icons';

export const openEnableBarnAlarmNotification = () =>
  showNotification({
    title: 'Barn Alarms Enabled',
    message: (
      <>
        You will recieve alarms from this barn.
        <Text fz="sm" mt={16} fw={500} c="dark">
          [TODO: Implement functionality]
        </Text>
      </>
    ),
    icon: <IconBellRinging />,
    color: 'blue',
  });
