import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons';

export const openBulkThresholdsModal = () =>
  openConfirmModal({
    title: 'Edit Alarm Thresholds',
    children: (
      <>
        <Text fz="sm" mt={16} fw={500} c="dimmed">
          [Placeholder for Threshold Modal]
        </Text>
      </>
    ),
    labels: { confirm: 'Save Changes', cancel: 'Cancel' },
    confirmProps: { color: 'blue' },
    cancelProps: { color: 'gray' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () =>
      showNotification({
        title: 'Alarm Thresholds Saved',
        message: 'Alarm Thresholds were updated.',
        icon: <IconCheck />,
        color: 'blue',
      }),
  });
