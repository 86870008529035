import { createStyles } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { appConfig } from '../appConfig';

export interface NavigationItem {
  link: string;
  label: string;
  isHomePage?: boolean;
  isExternal?: boolean;
}

export default function NavigationLinks(props: {
  links: NavigationItem[];
  onClick?: () => void;
}) {
  const { classes, cx } = useStyles();
  const location = useLocation();

  const items = props.links.map((link) =>
    link.isExternal ? (
      <a
        key={link.label}
        href={link.link}
        className={cx(classes.link, {
          [classes.linkActive]: link.isHomePage
            ? location.pathname === appConfig.navigationPrefix
            : location.pathname.startsWith(link.link),
        })}
      >
        {link.label}
      </a>
    ) : (
      <Link
        key={link.label}
        className={cx(classes.link, {
          [classes.linkActive]: link.isHomePage
            ? location.pathname === appConfig.navigationPrefix
            : location.pathname.startsWith(link.link),
        })}
        onClick={() => (props.onClick ? props.onClick() : null)}
        to={{ pathname: link.link, search: window.location.search }}
      >
        {link.label}
      </Link>
    )
  );

  return <>{items}</>;
}

const useStyles = createStyles((theme) => ({
  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 6px',
    borderRadius: 0,
    textDecoration: 'none',
    color: theme.colors.gray[4],
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    borderWidth: '3px 0',
    borderColor: 'transparent',
    borderStyle: 'solid',

    '&:hover': {
      color: 'white',
      borderBottomColor: 'rgba(256,256,256,.5)',
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
      color: theme.colors.gray[7],
      borderWidth: '0',

      '&:hover': {
        color: theme.colors.gray[9],
        backgroundColor: theme.colors.gray[0],
      },
    },
  },

  linkActive: {
    '&, &:hover': {
      color: 'white',
      borderBottomColor: theme.colors.red[6],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,

      '&, &:hover': {
        color: theme.colors.red[6],
      },
    },
  },
}));
