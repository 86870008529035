import { AnyAction, configureStore, Store } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { barnToolsApi } from '../api/base';
import organizationsReducer, {
  IOrganizationsState,
} from '../features/organizations/organizationsSlice';

export const store: Store<
  {
    organizations: IOrganizationsState;
  },
  AnyAction
> = configureStore({
  reducer: {
    organizations: organizationsReducer,
    [barnToolsApi.reducerPath]: barnToolsApi.reducer,
    // For local dev only, see base.acl.ts for more info
    // [localAclApi.reducerPath]: localAclApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      barnToolsApi.middleware,
      // For local dev only, see base.acl.ts for more info
      // localAclApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
