import {
  Center,
  createStyles,
  Flex,
  Grid,
  Group,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons';
import { ColumnProps, DataTableSortStatus } from '.';

type Props<T> = {
  columns: ColumnProps<T>[];
  onSort?: (status: DataTableSortStatus) => void;
  sortStatus?: DataTableSortStatus;
};

function DataTableHeader<T>(props: Props<T>) {
  const { classes } = useStyles();
  const headers = (
    <Grid className={classes.tableHeader} data-testid="datatable.header">
      {props.columns.map((c) =>
        ColumnHeader(c, props.sortStatus, props.onSort, classes)
      )}
    </Grid>
  );
  return <Flex direction="column">{headers}</Flex>;
}

function ColumnHeader<T>(
  props: ColumnProps<T>,
  sortStatus: DataTableSortStatus | undefined,
  onSort: ((status: DataTableSortStatus) => void) | undefined,
  classes: Record<'icon' | 'unimportant' | 'control', string>
) {
  const SortDirectionIcon =
    props.accessor == sortStatus?.columnAccessor
      ? sortStatus?.sortDirection == 'desc'
        ? IconChevronUp
        : IconChevronDown
      : IconSelector;
  const baseHeader = (
    <Text weight={500} size="sm">
      {props.title}
    </Text>
  );

  return (
    <Grid.Col
      className={props.important ? '' : classes.unimportant}
      span={props.span}
      style={props.important ? { width: '100%' } : {}}
      key={props.accessor}
    >
      {props.sortable && sortStatus ? (
        <UnstyledButton
          onClick={() => {
            if (onSort) {
              onSort({
                columnAccessor: props.accessor,
                sortDirection:
                  sortStatus.sortDirection === 'asc' ? 'desc' : 'asc',
              });
            }
          }}
          className={classes.control}
        >
          <Group position="apart" noWrap>
            {baseHeader}
            {props.sortable && (
              <Center className={classes.icon}>
                <SortDirectionIcon size={14} stroke={1.5} />
              </Center>
            )}
          </Group>
        </UnstyledButton>
      ) : (
        baseHeader
      )}
    </Grid.Col>
  );
}

export default DataTableHeader;

const useStyles = createStyles((theme) => ({
  tableHeader: {
    background: theme.fn.lighten(theme.colors.brand[1], 0.2),
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(234, 236, 240, 1)',
    borderRadius: '12px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    margin: 0,
    padding: '.25rem 1rem',
  },

  unimportant: {
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      display: 'none',
    },
  },

  control: {
    '&:hover': {
      color: theme.colors.dark[2],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));
