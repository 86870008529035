import { SensorType } from '@barntools/gateway-message-serialization';
import { faker } from '@faker-js/faker';
import { factory, manyOf, oneOf, primaryKey } from '@mswjs/data';
import { DateTime } from 'luxon';
import { initializeMockData } from './defaultMockData';

faker.seed(1);

export const db = factory({
  organization: {
    id: primaryKey(faker.datatype.uuid),
    name: faker.random.word,
  },

  User: {
    id: primaryKey(faker.datatype.uuid),
    oid: faker.datatype.uuid,
    name: faker.name.fullName,
    email: faker.internet.email,
    phone: faker.phone.number,
    avatar: faker.image.avatar,
    organizations: manyOf('organization'),
  },

  memberships: {
    User: oneOf('User'),
    Organization: oneOf('organization'),
    roleId: () => faker.datatype.number({ min: 0, max: 2 }),
    id: primaryKey(faker.datatype.uuid),
  },

  UserAssignedToGateway: {
    id: primaryKey(faker.datatype.uuid),
    User: oneOf('User'),
    gateway: oneOf('gateway'),
    roleId: () => faker.datatype.number({ min: 1, max: 2 }),
  },

  UserAssignedToGatewayGroup: {
    id: primaryKey(faker.datatype.uuid),
    User: oneOf('User'),
    gatewayGroup: oneOf('gatewayGroup'),
    roleId: () => faker.datatype.number({ min: 1, max: 2 }),
  },

  gateway: {
    serialNumber: primaryKey(faker.datatype.uuid),
    hasPower: faker.datatype.boolean,
    rssi: () => faker.datatype.number({ min: -100, max: 0 }),
    lastDataDateTime: () =>
      faker.datatype.datetime({
        min: new Date('2023').valueOf(),
        max: Date.now(),
      }),
    name: faker.random.word,
    thumbnail: faker.image.unsplash.nature,
    barnStatus: () =>
      faker.helpers.arrayElement(['Good', 'Warning', 'Silent', 'Emergency']),
    organization: oneOf('organization'),
    organizationId: faker.datatype.uuid,
    manager: oneOf('User'),
    AssignedUsers: manyOf('UserAssignedToGateway'),
  },

  gatewayGroup: {
    id: primaryKey(faker.datatype.uuid),
    name: faker.random.word,
    organization: oneOf('organization'),
    gateways: manyOf('gateway'),
    AssignedUsers: manyOf('UserAssignedToGatewayGroup'),
  },

  sensor: {
    id: primaryKey(faker.datatype.uuid),
    name: faker.random.word,
    sensorType: () => {
      const enumValues = Object.values(SensorType).filter(
        (n) => !isNaN(parseInt(n.toString()))
      );
      const randomIndex = faker.datatype.number({
        min: 0,
        max: enumValues.length - 1,
      });
      return enumValues[randomIndex] as SensorType;
    },
    order: Number,
    gateway: oneOf('gateway'),

    alertStatus: () => faker.helpers.arrayElement(['Enabled', 'Disabled']),
    batteryStatus: () =>
      faker.helpers.arrayElement(['Full', 'Good', 'Low', 'Empty']),
    sensorStatus: () =>
      faker.helpers.arrayElement(['Good', 'Warning', 'Emergency']),
    connectionStatus: () =>
      faker.helpers.arrayElement([
        'Strong',
        'Good',
        'Fair',
        'Poor',
        'No Connection',
      ]),
    lastDataDateTime: () =>
      faker.datatype.datetime({
        min: DateTime.now().minus({ minutes: 30 }).toMillis(),
        max: Date.now(),
      }),
  },

  alarm: {
    id: primaryKey(faker.datatype.uuid),
    sensor: oneOf('sensor'),
    message: faker.random.words,
  },
});

initializeMockData();
