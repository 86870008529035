import { Center, Loader } from '@mantine/core';
import { useGetGatewayGroupsQuery } from '../../api/gatewayGroups';
import GatewayGroup from './GatewayGroup';

type Props = {
  organizationId: string;
};

function GatewayGroups(props: Props) {
  const gatewayGroupFetch = useGetGatewayGroupsQuery({
    organizationId: props.organizationId ?? '',
  });

  if (gatewayGroupFetch.error) {
    return <>There was an error loading groups.</>;
  } else if (gatewayGroupFetch.isLoading) {
    return (
      <Center>
        <Loader></Loader>
      </Center>
    );
  }

  return (
    <>
      {(gatewayGroupFetch.data?.GatewayGroups ?? []).map((gg) => {
        return (
          <div key={gg.id}>
            <GatewayGroup gatewayGroup={gg} />
          </div>
        );
      })}
    </>
  );
}

export default GatewayGroups;
