import { MantineThemeColors } from '@mantine/core';
import { DeepPartial } from '@reduxjs/toolkit';

export const Colors: DeepPartial<MantineThemeColors> = {
  blue: [
    '#BFF1FF',
    '#A6DAFF',
    '#8DC4FF',
    '#73AEFF',
    '#5799FF',
    '#3885F2',
    '#0071DB',
    '#005DC4',
    '#003A98',
    '#004BAE',
  ],
  red: [
    '#FFB6BE',
    '#FF9FA8',
    '#FF8793',
    '#FF707E',
    '#FF586A',
    '#F73E57',
    '#DD1F44',
    '#C40033',
    '#AA0022',
    '#910012',
  ],
  yellow: [
    '#FFE150',
    '#FFCB37',
    '#FFB519',
    '#F19F00',
    '#D88B00',
    '#BF7600',
    '#A76200',
    '#8F4F00',
    '#793C00',
    '#642900',
  ],
  green: [
    '#8BFFB4',
    '#73F69E',
    '#5ADF89',
    '#40C874',
    '#1FB25F',
    '#009C4C',
    '#008638',
    '#007125',
    '#005C12',
    '#004800',
  ],
  brand: [
    '#F1F3F7',
    '#E0E2EE',
    '#C1C5D9',
    '#A5AAC1',
    '#898EA9',
    '#6D7493',
    '#565D79',
    '#3A4364',
    '#222E4E',
    '#081939',
  ],
};
