import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useGetUserQuery } from './api/users';
import { useAppDispatch } from './app/hooks';
import { AppShell } from './components/AppShell';
import { LoginPage } from './components/LoginPage';
import Dashboard from './features/dashboard/Dashboard';
import GatewayDetail from './features/gateways/GatewayDetail';
import GatewayList from './features/gateways/GatewayList';
import OrganizationDetail from './features/organizations/OrganizationDetail';
import OrganizationList from './features/organizations/OrganizationList';
import { setProfile } from './features/organizations/organizationsSlice';
import Reporting from './features/reporting/Reporting';
import PeoplePage from './features/users/PeoplePage';

type Props = {
  useMockServiceWorker: boolean;
};

function App({ useMockServiceWorker }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }
  const account = useAccount();
  const oid = account?.idTokenClaims?.oid;
  const userData = useGetUserQuery(
    useMockServiceWorker ? 'mock-user-oid' : oid ?? skipToken
  );

  useEffect(() => {
    if (userData.data) {
      dispatch(setProfile(userData.data));
    }
  }, [userData.data]);

  const links = [
    {
      link: '/',
      label: 'Home',
      isHomePage: true,
    },
    {
      link: `/gateways`,
      label: 'Barns',
    },
    {
      link: `/users`,
      label: 'People',
    },
    {
      link: `/reporting`,
      label: 'Reporting',
    },
    {
      link: `/organizations`,
      label: 'Organizations',
    },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {isAuthenticated || useMockServiceWorker ? (
          <Route element={<AppShell links={links} />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/organizations" element={<OrganizationList />} />
            <Route path="/organizations/:id" element={<OrganizationDetail />} />
            <Route path="/reporting" element={<Reporting />} />
            <Route path="/gateways" element={<GatewayList />} />
            <Route path="/gateways/:id" element={<GatewayDetail />} />
            <Route path="/users" element={<PeoplePage />} />
          </Route>
        ) : (
          <Route path="*" element={<LoginPage />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
