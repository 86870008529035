import { createStyles, TextInput, TextInputProps } from '@mantine/core';
import { IconSearch } from '@tabler/icons';

const useStyles = createStyles(() => ({
  search: {
    input: {
      borderRadius: '8px',
    },
  },
}));

export function SearchInput(props: TextInputProps) {
  const { classes } = useStyles();

  return (
    <TextInput
      className={classes.search}
      placeholder={props.placeholder}
      width={props.width}
      mb={props.mb}
      icon={<IconSearch size={16} stroke={2} color={'gray'} />}
      onChange={props.onChange}
    />
  );
}
