import { Button, Group, Modal, TextInput } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { useState } from 'react';
import { useUpdateOrgMutation } from '../../api/organizations';
import { IOrganization } from '../../api/types';

type Props = {
  onClose: () => void;
  organization: IOrganization;
};

function UpdateOrgModal({ onClose, organization }: Props) {
  const [newOrgName, setNewOrgName] = useState(organization.name);
  const [updateOrg] = useUpdateOrgMutation();

  const handleSubmit = () => {
    if (newOrgName.length > 0) {
      updateOrg({ id: organization.id, name: newOrgName });
    }
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };
  return (
    <Modal opened={true} onClose={onClose} title={'Update Organization'}>
      <TextInput
        aria-label="Organization Name"
        label="Organization Name"
        data-autofocus
        onKeyDown={getHotkeyHandler([['enter', handleSubmit]])}
        onChange={(e) => setNewOrgName(e.target.value)}
        value={newOrgName}
      />
      <Group pt="md" position="right">
        <Button color="gray" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="blue">
          Save
        </Button>
      </Group>
    </Modal>
  );
}

export default UpdateOrgModal;
