import {
  AppShell as Shell,
  Container,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import AppHeaderMobile from './AppHeaderMobile';
import { NavigationItem } from './NavigationLinks';

const useStyles = createStyles((theme) => ({
  main: {
    background:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[8]
        : theme.colors.brand[0],
    padding: '0',

    main: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  container: {
    maxWidth: '80%',
    marginTop: '24px',

    [theme.fn.smallerThan('lg')]: {
      maxWidth: '90%',
      marginTop: '14px',
    },

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginTop: '4px',
    },
  },
}));

export function AppShell(props: { links: NavigationItem[] }) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const largeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

  return (
    <Shell
      className={classes.main}
      navbarOffsetBreakpoint="sm"
      header={
        largeScreen ? (
          <AppHeader links={props.links} />
        ) : (
          <AppHeaderMobile links={props.links} />
        )
      }
    >
      <Container className={classes.container}>
        <Outlet />
      </Container>
    </Shell>
  );
}
